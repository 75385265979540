import React, { useRef, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ModalAV.module.scss';
import { sipHangUp,  sipAcceptCall, setOptions, sipToggleMute, fullScreen, sipToggleCamera } from '../../modules/SIP/servise';
import * as sipActions from '../../modules/SIP/store/actions';
import moment from 'moment';
import Modal from 'react-modal';
import setAvatar from '~/utils/setAvatar';
import { getFullNameByUser } from '~/utils/getFullName'; 

import {ReactComponent as IconCallBtn} from '~/icons/SIP/IconCallBtn.svg';
import {ReactComponent as IconEndCallBtn} from '~/icons/SIP/IconEndCallBtn.svg';
import {ReactComponent as IconVideoOnBtn} from '~/icons/SIP/IconVideoOnBtn.svg';
import {ReactComponent as IconVideoOffBtn} from '~/icons/SIP/IconVideoOffBtn.svg';
import {ReactComponent as IconAudioOnBtn} from '~/icons/SIP/IconAudioOnBtn.svg';
import {ReactComponent as IconAudioOffBtn} from '~/icons/SIP/IconAudioOffBtn.svg';
import {ReactComponent as IconFullscreenOnBtn} from '~/icons/SIP/IconFullscreenOnBtn.svg';
import {ReactComponent as IconFullscreenOffBtn} from '~/icons/SIP/IconFullscreenOffBtn.svg';

const ModalAV = ({ otherUser, modalRef, coordinates, onMouseDown, onMouseUp, isMobileCall, setMobileVideoCallStatus }) => {		

	const [timer, setTimer] = useState(0);
	const [timerId, setTimerId] = useState(null);

	const status = useSelector(state => state.sip.status);
	const isModalOpen = useSelector(state => state.sip.isModalOpen);
	const isMute = useSelector(state => state.sip.isMute);
	const isFullScreen = useSelector(state => state.sip.isFullScreen);
	const isConnection = useSelector(state => state.sip.isConnection);
	const isCallToMe = useSelector(state => state.sip.isCallToMe);
	const isCameraOf = useSelector(state => state.sip.isCameraOf);
	const connectionType = useSelector(state => state.sip.connectionType);

	const dispatch = useDispatch();	

	const audioRemoteRef = useRef(null);
  const videoLocalRef = useRef(null);
  const videoRemoteRef = useRef(null);
	const ringtone = useRef(null);
	const ringbacktone = useRef(null);
	
	useMemo(() => {
		if (videoRemoteRef.current && videoLocalRef.current && audioRemoteRef.current) {			
			setOptions(videoRemoteRef.current, videoLocalRef.current, audioRemoteRef.current, (data) => 
			dispatch(sipActions.changeStatus(data)));											
		}		
	}, [videoRemoteRef.current, videoLocalRef.current, audioRemoteRef.current])
	
	useMemo(() => {
		if (isModalOpen === false) {
			setTimeout(() => {
				dispatch(sipActions.setConnectionType(''));
				dispatch(sipActions.setOtherUser({}));
				dispatch(sipActions.setOtherNumber(''));
			}, 1000);
		}			
	}, [isModalOpen])

	useMemo(() => {
		if (status === 'i_new_call') {		
			dispatch(sipActions.setIsCallToMe(true));
			startRingTone();		
			!isModalOpen && setTimeout(() => dispatch(sipActions.openModal(true)), 1000);							
		} else if(status === 'terminated') {
			dispatch(sipActions.openModal(false));
			dispatch(sipActions.setConnectionStatus(false));
			dispatch(sipActions.setFullScreen(false));
			stopRingTone();				
			sipToggleCamera(
				(param) => dispatch(sipActions.toggleCamera(param)),
				false
			);			
		} else if(status === 'i_ao_request') {			
			dispatch(sipActions.setConnectionStatus(false));			
		} 
	}, [status])
	
	useEffect(() => {
		if (status === 'm_stream_video_remote_added') {
			dispatch(sipActions.setConnectionType('video'));			
		}	
	}, [status === 'm_stream_video_remote_added'])	

	useMemo(() => {
		if(isConnection === true) {
			startTimer();
			setTimeout(() => setMobileVideoCallStatus({
				videoRef:	videoRemoteRef.current,					
			}), 100)
			
		} else if(isConnection === false) {
			stopTimer();
		} 
	}, [isConnection])

	const customStyles = {
		content: {
			width: isFullScreen ? '100%' : 'auto',
			height: isFullScreen ? '100vh' : 'auto',
			overflow: isFullScreen && 'hidden',			
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			padding: '0',
			transform: 'translate(-50%, -50%)',
			borderRadius: !isFullScreen && '8px'
		},
		overlay: {
      position: 'fixed',
			top: '0',
			left: '0',
			right: '0',
			zIndex: isModalOpen ? '10000' : '-1',
			opacity: isModalOpen ? '1' : '0',			
			background: 'rgba(0, 0, 0, 0.25)',
			transition: '1s'
    },
	}	

	const checkingStyles = {
		content: {
			position: 'absolute',
			top: isFullScreen ? '0' : `${coordinates.top}px`,
			right: isFullScreen ? '0' : `${coordinates.right}px`,
			left: 'auto',
			bottom: 'auto',
			width: isFullScreen ? '100%' : 'auto',
			height: isFullScreen ? '100vh' : 'auto',
			overflow: isFullScreen && 'hidden',			
			padding: '0',
			border: 'none',
			borderRadius: !isFullScreen && '8px'
		},
		overlay: {
      position: 'fixed',
			top: '0',
			left: isFullScreen ? '0' : 'auto',
			right: '0',
			bottom: isFullScreen ? '0' : 'auto',
			zIndex: isModalOpen ? '10000' : '-1',
			opacity: isModalOpen ? '1' : '0',			
			background: 'rgba(0, 0, 0, 0.0)',
			transition: '1s'
    },
	}	

	/* 	const [deactivateAudio, setDeactivateAudio] = useState(false);
	
	const interfaceConfig = {
		DEFAULT_BACKGROUND: '#ffffff',
		HIDE_INVITE_MORE_HEADER: true,
		SETTINGS_SECTIONS: [],
		SHARING_FEATURES: [],
		VIDEO_QUALITY_LABEL_DISABLED: true,
		VERTICAL_FILMSTRIP: false,
		FILM_STRIP_MAX_HEIGHT: 80
	};

	const containerStyle = {

		width: '450px',
		height: '450px',
	};

	const config = {

		disableModeratorIndicator: true,
		hideAddRoomButton: true,
		disableProfile: true,
		disableAddingBackgroundImages: true,
		hideConferenceSubject: true,
		hideConferenceTimer: true,
		connectionIndicators: {

			disabled: true,
			disableDetails: true,
			inactiveDisabled: true,
		},
		disableResponsiveTiles: true,
		hideLobbyButton: true,
		toolbarButtons: [
			// 'camera',
			// 'chat',
			// 'closedcaptions',
			// 'desktop',
			// 'download',
			// 'embedmeeting',
			// 'etherpad',
			// 'feedback',
			// 'filmstrip',
			// 'fullscreen',
			// 'hangup',
			// 'help',
			// 'invite',
			// 'livestreaming',
			// 'microphone',
			// 'mute-everyone',
			// 'mute-video-everyone',
			// 'participants-pane',
			// 'profile',
			// 'raisehand',
			// 'recording',
			// 'security',
			// 'select-background',
			// 'settings',
			// 'shareaudio',
			// 'sharedvideo',
			// 'shortcuts',
			// 'stats',
			// 'tileview',
			// 'toggle-camera',
			// 'videoquality',
			// '__end'
		],
	};

	let api;

	const handleAPI = (Api) => {
		api = Api;
		Api.executeCommand('toggleFilmStrip');

		if (isAudioOnly) {
			Api.executeCommand('muteEveryone', 'video');
		}
	};

	const handleAudio = () => {
		api.executeCommand('toggleAudio');
	};

	const handleVideo = () => {
		api.executeCommand('toggleVideo');
	};

	const handleFull = () => {

	} */

	const handleAudio = () => {
		sipToggleMute(
			() => dispatch(sipActions.makeMute(!isMute))
		); 	
	};	

	const handleVideo = async () => {		
		sipToggleCamera(
			(param) => dispatch(sipActions.toggleCamera(param))
		); 	
	};

	const handleFull = () => {		
		fullScreen({isFullScreen, callback: (data) => {
			dispatch(sipActions.setFullScreen(data));			
		}})
	};

	const acceptCall = () => {
		sipAcceptCall();		
		dispatch(sipActions.setIsCallToMe(false));
		stopRingTone();
		dispatch(sipActions.setConnectionStatus(true));			
	};

	const hangUp = () => {	
		dispatch(sipActions.openModal(false));
		sipHangUp((data) => dispatch(sipActions.changeStatus(data)));
		dispatch(sipActions.setIsCallToMe(false));
		dispatch(sipActions.setFullScreen(false));
		dispatch(sipActions.setConnectionStatus(false));
		stopRingTone();		
		dispatch(sipActions.toggleCamera(false));	  	
	};

	const onClose = () => {
		dispatch(sipActions.openModal(false));		
		sipHangUp((data) => dispatch(sipActions.changeStatus(data)));
		dispatch(sipActions.setConnectionStatus(false));
		dispatch(sipActions.setIsCallToMe(false));
		dispatch(sipActions.setFullScreen(false));
		stopRingTone();			
		dispatch(sipActions.toggleCamera(false));					 
	};	
	
	function startRingTone() {
		ringtone.current.play();
	}

	function stopRingTone() {
		ringtone.current.pause();
	}		

	
	function startTimer() {
		let tick = setInterval(() =>	{					
			setTimer(timer => timer + 1000)
		}, 1000);
		setTimerId(tick);		
	}

	function stopTimer() {	
		clearInterval(timerId)
		setTimer(0)
	}	
	
	function ButtonBlock({onMouseDown}) {
		if (isCallToMe) {
			return (
				<div className={styles.btnBlock} data-drag='noDrag' onMouseDown={onMouseDown} >					
					<span
						className={styles.call_active}
						onClick={acceptCall}						
					>
						<IconCallBtn />	
					</span>
					<span
						className={styles.call}
						onClick={hangUp}						
					>
						<IconEndCallBtn />	
					</span>					
				</div>	
			);	
		} else if (connectionType === 'video') {			
			return (
				<div className={styles.btnBlock} data-drag='noDrag' onMouseDown={onMouseDown} >
					<span
						className={isCameraOf ? styles.notVideo : styles.video}
						onClick={handleVideo}					
					>
						{isCameraOf ? <IconVideoOffBtn /> : <IconVideoOnBtn />}
					</span>						
					<span
						className={styles.call}
						onClick={hangUp}					
					>
						<IconEndCallBtn />	
					</span>						
					<span	
						className={isMute ? styles.notAudio : styles.audio}						
						onClick={handleAudio}					
					>
						{isMute ? <IconAudioOffBtn /> : <IconAudioOnBtn />}
				</span>							
			</div>	
			);		
		} else {
			return (
				<div className={styles.btnBlock} data-drag='noDrag' onMouseDown={onMouseDown} >										
					<span
						className={styles.call}
						onClick={hangUp}						
					>
						<IconEndCallBtn />	
					</span>						
					<span	
						className={isMute ? styles.notAudio : styles.audio}						
						onClick={handleAudio}						
					>
						{isMute ? <IconAudioOffBtn /> : <IconAudioOnBtn />}
					</span>							
				</div>	
			);			
		}
	}	

	const videoRemoteAvatar = {			
		backgroundImage: `url(${setAvatar({
			isDoctor: otherUser.isDoctor,
			isPatient: otherUser.isPatient,
			gender: otherUser.gender,
			photo: otherUser.photo,
		})})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover'
	};
	
	return (
		<Modal 
			onRequestClose={onClose} 
			isOpen={true}
			shouldCloseOnOverlayClick={false}	
			style={checkingStyles}
		>	
			<div 
				className={isFullScreen ? styles.block_full : styles.block}
				ref={modalRef}				
				onMouseDown={onMouseDown}
				onMouseUp={onMouseUp}	
			>
				<div className={`${connectionType === 'video' ? styles.withVideo : styles.withoutVideo}`}>				
					<div className={styles.videoBlock}>
						<div>
							<audio
								id="audio_remote"
								autoPlay="autoplay"
								ref={audioRemoteRef}
							/>
							<audio
								id="ringtone"
								loop
								ref={ringtone}
								src="sounds/ringtone.wav"
							/>
							<audio
								id="ringbacktone"
								loop
								ref={ringbacktone}
								src="sounds/ringbacktone.wav" />    				
						</div>
						<div>
						<span
								className={isFullScreen ? styles.fullscreenOnBtn : styles.fullscreenOffBtn}
								onClick={handleFull}
								data-drag='noDrag'
								onMouseDown={onMouseDown}
							>
								{isFullScreen ? <IconFullscreenOffBtn /> : <IconFullscreenOnBtn />}
							</span>
							<div
								className={`${styles.videoRemote} ${isMobileCall ? styles.mobile : ''}`}								
							>	
								<div
									className={styles.video}																		
								>
									<video
										className={`${!isConnection ? styles.transparent : ''}`}																																					
										id="video_remote"
										autoPlay='autoplay'
										ref={videoRemoteRef}
										style={isFullScreen && !isMobileCall ? {marginBottom: `-${window.innerHeight/2}px`} : null}
									/>
								</div>								
								<div
									className={`${styles.videoRemoteAvatar} ${!isConnection ? styles.animatePulse : ''}`}
									style={videoRemoteAvatar}
								/>																	
							</div>				
							<div className={`${styles.videoLocal} ${isCameraOf ? styles.cameraOff : ''} ${styles.border}`}>
								<video														
									id="video_local"
									autoPlay="autoplay"
									muted={true}								
									ref={videoLocalRef}								
								/>
							</div>	
						</div>					
					</div>								
					<div className={styles.controlBlock}>
						<div className={styles.textBlockContainer}>
							{connectionType !== 'video' && 
							<div className={`${styles.avatarBlock} ${!isConnection ? styles.animatePulseSmall : ''}`}>
								<img src={setAvatar({
									isDoctor: otherUser.isDoctor,
									isPatient: otherUser.isPatient,
									gender: otherUser.gender,
									photo: otherUser.photo,
								})} />
							</div>}
							<div className={styles.textBlock}>
								<div className={styles.nameBlock}>
									{getFullNameByUser(otherUser)}
								</div>
								<div className={styles.statusBlock}>
									{!isConnection ? 'Вызов...' : moment(timer).format('mm:ss')}
								</div>
							</div>							
						</div>						
						< ButtonBlock onMouseDown={onMouseDown} />
						{isFullScreen && <div className={styles.fullscreenOnBtnContainer}></div>}																							
					</div>
				</div> 	
			</div>					
		</Modal>		
	);
};


ModalAV.propTypes = {
	otherUser: PropTypes.object.isRequired,
};

export default ModalAV;