import { USER_LIST } from './action-types'

const initialState = {
    list: [],
}
const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case USER_LIST:
            return list(state, payload)
        default:
            return state
    }
}

function list(state, payload) {
    state = {
        ...state,
        list: payload
    }

    return state
}

export default reducer
