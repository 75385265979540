// import lib
import { lazy } from 'react'

export default [

  {
    path: '/system-admin',
    exact: true,
    auth: true,
    sysAdmin: true,
    breadcrumbs: [
      {
        'href': '/system-admin',
        'label' : 'Главная / Медицинские организации'
      },
    ],
    title: '',
    searchMode: 'medOrgs',
    component: lazy(() => import('./pages/MedOrgsPage/index')),
  },
  {
    path: '/system-admin/medorgs/:id',
    exact: true,
    auth: true,
    sysAdmin: true,
    breadcrumbs: [
      {
        'href': '/system-admin',
        'label' : 'Главная / Медицинские организации'
      },     
      {
        'href': '/system-admin/medorgs/:id',
        'label' : 'Медицинская организация'
      },
    ],
    title: 'Данные о медицинском учреждении',

    component: lazy(() => import('./pages/MedOrgPage/index')),
  },
  {
    path: '/system-admin/edit/:id',
    exact: true,
    auth: true,
    sysAdmin: true,
    breadcrumbs: [
      {
        'href': '/system-admin',
        'label' : 'Главная / Медицинские организации'
      },
      {
        'href': '/system-admin/edit',
        'label' : 'Редактирование медицинского учреждения'
      },
    ],
    title: 'Изменение данных о медицинском учреждении',
    component: lazy(() => import('./pages/MedOrgEdit/index')),
  },
  {
    path: '/system-admin/add',
    exact: true,
    auth: true,
    sysAdmin: true,
    breadcrumbs: [
      {
        'href': '/system-admin',
        'label' : 'Главная / Медицинские организации'
      },
      {
        'href': '/system-admin/add',
        'label' : 'Добавление медицинского учреждения'
      },
    ],
    title: 'Добавление данных о медицинском учреждении',
    component: lazy(() => import('./pages/MedOrgEdit/index')),
  },
  {
    path: '/system-admin/*',
    exact: true,
    auth: true,
    sysAdmin: true,
    redirect: true,
    component: lazy(() => import('./pages/MedOrgsPage/index')),
  },
]
