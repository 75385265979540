function passwordValidation(password) {
  
  const patterns = {
    'numeric': '0-9',  
    'latin_lower': 'a-z',
    'latin_upper': 'A-Z',
    'special': '!@#$%^&*',
  };  
  const min = 8;  
  let regex_string = '';
  const rules = [];
  let range = `{${min},}`; 
  for (let rule in patterns) { 
    if (patterns.hasOwnProperty(rule)) {
      rules.push(patterns[rule]);     
      regex_string += `(?=.*["${patterns[rule]}])`;
    }
  }  
  regex_string += `[${rules.join('')}]${range}`;
  
  let regExp = new RegExp(regex_string);
  
  return regExp.test(password)
}

export default passwordValidation;