function checkDownloadPermission(filesForUpload, alreadyUploadedFiles, isVideoAlso = false) {	
  const typesWithoutVideo = [
    'image/png',
    'image/jpeg',
    'application/pdf',
    'image/tiff',
    'image/gif'	
  ];

  const typesWithVideo = [
    'image/png',
    'image/jpeg',
    'application/pdf',
    'image/tiff',
    'image/gif',
    'video/mp4',
    'video/ogg',
    'video/webm',
  ];

  let isTypesOk = false;
  let types = isVideoAlso ? typesWithVideo : typesWithoutVideo;

  if (alreadyUploadedFiles.length + filesForUpload.length <= 10) {
    for (let i = 0; i < filesForUpload.length; i++) {
      types.forEach(type => {

        if(filesForUpload[i].type === type) {
          isTypesOk = true;
        }
      })
      if (filesForUpload[i].size <= 5242880 && isTypesOk) {					
        return true;	
      }	else {					
        return false
      }	
    }			
  } else {
    return false;
  }		
};

export default checkDownloadPermission;