import { toast } from 'react-toastify';
import Http from '../../utils/Http';
import returnServerMessage from '~/utils/returnServerMessage';

export function sendMessageRequest(messageData, callback=null) {
  return dispatch => {
    const url = '/support/sendmail';
    const data = new FormData();
    	 
    for (let key in messageData) {
      if (key !== 'files') {
        data.append(`${key}`, messageData[key])
      }      
    }

	  for (let i = 0; i < messageData.files.length; i++) {
		  data.append(`files[${i}]`, messageData.files[i]);
	  }    
    
	  Http.post(url, data)
		  .then((res) => { 
        callback && callback(returnServerMessage(res));        
		  })
		  .catch((err) => {                 
        toast(returnServerMessage(err));        	
		  })
  };	
}