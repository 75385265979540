import 'pusher-js';
import Echo from 'laravel-echo';

export default (config = {}) =>
  new Echo({
    broadcaster: 'pusher',
    wsHost: window.location.host,    
    wsPort: 6001,
    forceTLS: true,
    disableStats: true,
    transports: ['websocket', 'polling', 'flashsocket'],
    enabledTransports: ['ws', 'wss'],
    disabledTransports: ['sockjs', 'xhr_polling', 'xhr_streaming'],
    
    ...config,
  });
