import React from 'react';
import { Nav } from 'reactstrap';

const SideMenu = (props) => {
  return (
    <div className="side-menu">
      <Nav vertical className="list-unstyled pb-3">
        {props.children}     
      </Nav>
    </div>
  );
};

export default SideMenu;