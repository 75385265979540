import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './ModalBlock.module.scss';
import RootModal from '../RootModal/RootModal';
import Button from '../../components/Button/Button';

const ModalBlock = ({
  title,
  subTitle,
  isOpen,
  onClose,
  actions,
  children,
  classNames,
  actionsNode,
}) => {
  return (
    <RootModal onClose={onClose} isOpen={isOpen}>
      <div className={classnames(styles.block, classNames)}>
        {title && <div className={styles.title}>{title}</div>}
        {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
        {children}
        {actions && actionsNode === undefined && (
          <div
            className={styles.buttons}
            style={{
              gridTemplateColumns: `repeat(${actions.length}, 1fr)`,
            }}>
            {actions.map((action, index) => (
              <Button
                key={index}
                mode={action.mode}
                onClick={action.onClick}
                disabled={action.disabled}>
                {action.text}
              </Button>
            ))}
          </div>
        )}
        {actionsNode}
      </div>
    </RootModal>
  );
};

ModalBlock.propTypes = {
  doctors: PropTypes.arrayOf(PropTypes.any),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  classNames: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      mode: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
    })
  ),
  actionsNode: PropTypes.node,
  onSubmit: PropTypes.func,
};

export default ModalBlock;
