import React from 'react';
import PropTypes, { nominalTypeHack } from 'prop-types';
import styles from './ModalNotify.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import Input from '../../components/Input/Input';
import InputSlot from '../../components/InputSlot/InputSlot';
import Button from '../../components/Button/Button';
import Modal from 'react-modal';
import IconButton from '../../components/IconButton/IconButton';

import {ReactComponent as Notify} from '~/icons/IconNotify.svg';
import {ReactComponent as NoNotify} from '~/icons/IconNoNotify.svg';


const displayName = 'ModalNotify';
const propTypes = {
	onSubmit: PropTypes.func,
	onChange: PropTypes.func,
	ruleEdit: PropTypes.object,
	errors: PropTypes.object,
};

const ModalNotify = ({onChange, isOpen, onClose, isNotify}) => {
	const customStyles = {
		overlay: {
			backgroundColor: 'rgba(255, 255, 255, 0)'
		},
		content: {
				top: '70px',
				left: 'auto',
				right: '3px',
				bottom: 'auto',
				// marginRight: '-50%',
				padding: '10px',
				border: nominalTypeHack,
				zIndex: 5
				
				
				// transform: 'translate(-50%, -50%)',
		},
}

	
	return (
		<Modal
			onRequestClose={onClose}
		  isOpen={isOpen}
			style={customStyles}
		>
		
			<div className={styles.modalBody}>				
				<div>
				<IconButton size="lg" onClick={onChange}>
              {isNotify ?
                <Notify />
                :
                <NoNotify />
                
              }

            </IconButton>
				</div>

				<div>
					{isNotify ? 
					<span
						className={styles.on}>Уведомление включено
					</span> :
				  <span
						className={styles.of}>Уведомление отключено
					</span>}
					
				</div>
			</div>


		</Modal>
	);
};

ModalNotify.displayName = displayName;
ModalNotify.propTypes = propTypes;

export default ModalNotify;
