export const RULE_LIST = 'RULE_LIST';
export const RULE_ADD = 'RULE_ADD';
export const RULE_UPDATE = 'RULE_UPDATE';
export const RULE_REMOVE = 'RULE_REMOVE';

export default {
  RULE_LIST,
  RULE_ADD,
  RULE_UPDATE,
  RULE_REMOVE,
}