export const TYPE_LIST = 'TYPE_LIST'
export const TYPE_ADD = 'TYPE_ADD'
export const TYPE_UPDATE = 'TYPE_UPDATE'
export const TYPE_REMOVE = 'TYPE_REMOVE'

export default {
  TYPE_LIST,
  TYPE_ADD,
  TYPE_UPDATE,
  TYPE_REMOVE,
}