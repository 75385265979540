/* eslint-disable no-console */
import axios from 'axios';
import store from '../store/index';
import { authLogout } from '../modules/auth/store/actions';
import { BASE_URL } from '../common/url';


const version = 'v1';

//const API_URL = `http://telemed2.local/api/${version}/`;
//const API_URL = `http://10.0.4.194/api/${version}/`;
axios.defaults.baseURL = `${BASE_URL}/api/${version}`;
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common['X-CSRF-TOKEN'] =
  process.env.REACT_APP_CSRF_TOKEN;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(authLogout());
    }
    return Promise.reject(error);
  }
);

export default axios;
