// import modular routes
import webRoutes from "../modules/web/routes"
import authRoutes from "../modules/auth/routes"
import userRoutes from "../modules/user/routes"
import adminRoutes from "../modules/admin/routes"
import doctorRoutes from "../modules/doctor/routes"
import patientRoutes from "../modules/patient/routes"
import sysAdminRoutes from "../modules/systemAdmin/routes"
import landingRoutes from "../modules/landing/routes.ts"

export default [...webRoutes, ...authRoutes, ...userRoutes, ...adminRoutes, ...doctorRoutes, ...patientRoutes, ...sysAdminRoutes, ...landingRoutes]

