import Model from '../../utils/Model';

class Rule extends Model {
  constructor(props) {
    super(props);

    this.initialize(props);
  }

  initialize(props) {
    this.id = props?.id || '';
    this.name = props?.name || '';
    this.description = props?.description || '';
    this.medicOrganizationId = props?.medicOrganizationId || '';
    this.active = props?.active || false;
    this.archive = props?.archive || false;
    this.hoursOnAnswer = props?.hoursOnAnswer || '';
    this.notWorkTime = props?.notWorkTime || false;
    this.holidays = props?.holidays || false;
    this.workHourStart = props?.workHourStart || '';
    this.workHourEnd = props?.workHourEnd || '';
  }
}

export default Rule;
