import Model from '../../utils/Model';
import moment from 'moment';
import { number } from 'prop-types';

class Users extends Model {
  constructor(props) {
    super(props);

    this.initialize(props);
  }

  get fullName() {
    return `${this.lastName} ${this.name} ${this.secondName}`;
  }

  initialize(props) {
    super.initialize(props);
    this.name = props.name || '';
    this.lastName = props.lastName || '';
    this.secondName = props.secondName || '';
    this.email = props.email || '';
    this.phone = props.phone || '';
    this.photo = props.photo || '';
    this.doctorsSpecialty = props.doctorsSpecialty || '';
    this.birthDay = (props.birthDay && moment(props.birthDay)) || null;
    this.active = props.active || false;
    this.activated = props.activated || false;
    this.isDoctor = props.isDoctor || false;
    this.isPatient = props.isPatient || false;
    this.isSystemAdmin = props.isSystemAdmin || false;
    this.isAdmin = props.isAdmin || false;
    this.vacation = props.vacation || false;
    this.fired = props.fired || false;
    this.contracts = props.contracts || false;
    this.gender = props.gender || false;
    this.contractsDoctor = props.contractsDoctor || false;
    this.countContractsActive = props.countContractsActive || [];
    this.countContractsArchive = props.countContractsArchive || [];    
    this.mark = props.mark || null;
  }
}

export default Users;
