export const USER_LIST = 'USER_LIST';
export const CONTRACT_LIST = 'CONTRACT_LIST';
export const CONTRACT_ADD = 'CONTRACT_ADD';
export const CONTRACT_UPDATE = 'CONTRACT_UPDATE';
export const CONTRACT_REMOVE = 'CONTRACT_REMOVE';
export const UPDATE_CONTRACTS_LIST = 'UPDATE_CONTRACTS_LIST';
export const SET_ACTIVE_CONTRACT = 'SET_ACTIVE_CONTRACT';

export default {
  CONTRACT_LIST,
  USER_LIST,
  CONTRACT_ADD,
  CONTRACT_UPDATE,
  CONTRACT_REMOVE,
  UPDATE_CONTRACTS_LIST,
  SET_ACTIVE_CONTRACT,
}