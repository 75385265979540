import {
    SEARCH_CLEAR,
    SEARCH_MODE,
    SEARCH_PLACEHOLDER,
    SEARCH_TYPED_FN,
    SEARCH_VALUE,
} from './action-types'

export function setSearchMode(mode) {
    return {
        type: SEARCH_MODE,
        payload: mode,
    }
}

export function setSearchTypedFn(cb) {
    return {
        type: SEARCH_TYPED_FN,
        payload: cb,
    }
}

export function setSearchValue(value) {
    return {
        type: SEARCH_VALUE,
        payload: value,
    }
}

export function setSearchPlaceholder(value) {
    return {
        type: SEARCH_PLACEHOLDER,
        payload: value,
    }
}

export function clearSearch() {
    return {
        type: SEARCH_CLEAR,
    }
}
