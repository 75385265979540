export const SEARCH_MODE = 'SEARCH_MODE'
export const SEARCH_TYPED_FN = 'SEARCH_TYPED_FN'
export const SEARCH_VALUE = 'SEARCH_VALUE'
export const SEARCH_CLEAR = 'SEARCH_CLEAR'
export const SEARCH_PLACEHOLDER = 'SEARCH_PLACEHOLDER'

export default {
  SEARCH_MODE,
  SEARCH_TYPED_FN,
  SEARCH_VALUE,
  SEARCH_CLEAR,
  SEARCH_PLACEHOLDER
}
