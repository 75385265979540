import moment from 'moment';
import Model from '../../utils/Model';
import User from '../user/User';
import Rule from '../rule/Rule';
import Type from '../type/Type';
import Medorg from '../medorg/Medorg';

class Contract extends Model {
  constructor(props) {
    super(props);

    this.initialize(props);
  }

  initialize(props) {
    super.initialize(props);

    this.id = props.id || '';
    this.name = props.name || '';
    this.number = props.number || '';
    this.rating = props.rating || '';
    this.typeContractId = props.typeContractId || 0;
    this.statusContractId = props.statusContractId || 0;
    this.patientUserId = props.patientUserId || 0;
    this.doctorUserId = props.doctorUserId || 0;
    this.medOrganizationId = props.medOrganizationId || 0;
    this.active = props.active || false;
    this.message = props.message || {
      sumPatient : 0,
      sumDoctor: 0,
      sumDelay: 0,
    };    
    /*this.patientUserId = props.patientUserId ? new User(props.patientUserId) : null
    this.doctorUserId = props.doctorUserId ? new User(props.doctorUserId) : null*/
    this.ruleId = props.ruleId || 1;
    this.iridaRoomId = props.iridaRoomId || 1;
    this.activeDateStart = props.activeDateStart
      ? moment(props.activeDateStart)
      : moment();
    this.activeDateEnd = props.activeDateEnd
      ? moment(props.activeDateEnd)
      : moment();
    this.consultationDateEnd = props.consultationDateEnd
      ? moment(props.consultationDateEnd)
      : moment();
    this.consultationDateStart = props.consultationDateStart
      ? moment(props.consultationDateStart)
      : moment();
    this.docs = props.docs || '';
    // relate user model
    this.patient = props.patient ? new User(props.patient) : new User({});
    this.doctor = props.doctor ? new User(props.doctor) : new User({});
    this.type = props.type ? new Type(props.type) : new Type({});
    this.rule = props.rule ? new Rule(props.type) : new Rule({});
    this.meetingRoom = props.meetingRoom || null;
    this.medOrganization = props.medOrganization ? new Medorg(props.medOrganization) : new Medorg({});
    this.comment = props.comment || '';
  }

  serialize(data) {
    return {
      active: data.active,
      type_contract_id: data.typeContractId,
      doctor_user_id: data.doctorUserId,
      patient_user_id: data.patientUserId,
      rule_id: data.ruleId,
      med_organization_id: data.medOrganizationId,
      active_date_end: moment(data.activeDateEnd).format('DD.MM.YYYY'),
    };
  }
}

export default Contract;
