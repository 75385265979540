import Model from '../../utils/Model'
import moment from "moment";

class User extends Model {
  constructor(props) {
    super(props)

    this.initialize(props)
  }

  get fullName() {
    return `${ this.lastName } ${ this.name } ${ this.secondName }`
  }

  initialize(props) {
    super.initialize(props)


    this.name = props.name || ''
    this.id = props.id || ''
    this.lastName = props.lastName || ''
    this.secondName = props.secondName || ''
    this.email = props.email || ''
    this.phone = props.phone || ''
    this.mark = props.mark || ''
    this.photo = props.photo || ''
    this.birthDay = props.birthDay && moment(props.birthDay) || null
    this.doctorsSpecialty = props.doctorsSpecialty || ''
    this.active = props.active || false
    this.activated = props.activated || false
    this.isDoctor = props.isDoctor || false
    this.isPatient = props.isPatient || false
    this.isSystemAdmin = props.isSystemAdmin || false
    this.isAdmin = props.isAdmin || false
    this.vacation = props.vacation || false
    this.fired = props.fired || false
    this.contracts = props.contracts || false
    this.gender = props.gender || false
    this.contractsDoctor = props.contractsDoctor || false
    this.countContractArchive = props.countContractArchive || false
    this.countContractActive = props.countContractActive || false
    this.medOrganizationId = props.medOrganizationId || 0
    this.userNumber = props.userNumber || ''
  }
}

export default User
