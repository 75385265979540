import {
  CONTRACT_ADD,
  CONTRACT_UPDATE,
  CONTRACT_REMOVE,
  CONTRACT_LIST,
  USER_LIST,
  UPDATE_CONTRACTS_LIST,
  SET_ACTIVE_CONTRACT,
} from './action-types'

const initialState = {
  currentPage: 0,
  data: [],
  from: 0,
  lastPage: 0,
  nextPageUrl: '',
  path: '',
  perPage: 0,
  prevPageUrl: null,
  to: 0,
  total: 0,
  activeContract: {},
}

const reducer = (state = initialState, { type, payload = null }) => {
  switch(type) {
    case CONTRACT_ADD:
      return add(state, payload)
    case CONTRACT_UPDATE:
      return update(state, payload)
    case CONTRACT_REMOVE:
      return remove(state, payload)
    case CONTRACT_LIST:
      return list(state, payload)
    case USER_LIST:
      return list(state, payload)
    case UPDATE_CONTRACTS_LIST:
      return updateContractsList(state, payload)
    case SET_ACTIVE_CONTRACT:
      return setActiveContract(state, payload)      
    default:
      return state
  }
}

function add(state, payload) {
  const contract = state.data.find((contract) => (contract.id === payload.id))

  if (!contract) {
    const data = [...state.data, payload]

    return Object.assign({}, state, { data })
  }

  return update(state, payload)
}

function update(state, payload) {
  const data = state.data.map(obj => {
    if (obj.id === payload.id) {
      return { ...obj, ...payload }
    }
    return obj
  })

  return Object.assign({}, state, { data })
}

function remove(state, id) {
  const data = state.data.filter(obj => obj.id !== id)

  return Object.assign({}, state, { data })
}

function list(state, payload) {
  state = Object.assign({}, state, payload)

  return state; 
}

function updateContractsList(state, payload) {
  let data = [];

  [ ...payload.data, ...state.data].sort((prev, next) => {
    return (prev.id - next.id)
  }).forEach((item, i) => {    
    let check = 0;

    if (i > 0) {      
      data.forEach(item2 => {
        
        if (item.id == item2.id) {
          check = 1;
        }
      })
    }  

    if (check === 0) {
      data.push(item); 
    }
  });    

  return {
    ...state,
    data,
    currentPage: payload.currentPage
  }
}

function setActiveContract(state, payload) {
  return {
    ...state,    
    activeContract: {...state.activeContract, ...payload}
  }
}

export default reducer
