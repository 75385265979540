import { UPDATE_FORM } from './action-types'

const initialState = {
    name: '',
    clinic: '',
    phone: '',
    message: '',
}

const reducer = (state = initialState, { type, payload = null }) => {
  switch(type) {
    case UPDATE_FORM:
      return update(state, payload)               
    default:
      return state
  }
};

function update(state, payload) {
  return {
    ...state,
      name: payload.name,
      clinic: payload.clinic,
      phone: payload.phone,
      message: payload.message,
  }
}

export default reducer