// import libs
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect, useSelector} from 'react-redux'
import { logout } from '../../modules/auth/service'

// import components
import { Link } from 'react-router-dom'
import { Navbar, NavbarToggler } from 'reactstrap'
import PrivateHeader from './PrivateHeader'
import Search from "./../Search/Search";
import styles from './styles.module.scss'

import Logo from '~/images/heart.png'

class Navigation extends Component {
    static propTypes = {
        isAuthenticated: PropTypes.bool.isRequired,
        user: PropTypes.object.isRequired,
        dispatch: PropTypes.func.isRequired,
        toggleSidebar: PropTypes.func,
        searchMode: PropTypes.string,
    }

    constructor(props) {
        super(props)

        this.state = {
            showNavigation: false,
            showDropdown: false,
        }

        this.toggleNavbar = this.toggleNavbar.bind(this)
        this.toggleDropdown = this.toggleDropdown.bind(this)
        this.logout = this.logout.bind(this)
    }

    toggleNavbar() {
        this.setState({
            showNavigation: !this.state.showNavigation,
        })
    }

    toggleDropdown() {
        this.setState({
            showDropdown: !this.state.showDropdown,
        })
    }

    logout(e) {
        e.preventDefault()

        this.props.dispatch(logout())
    }

    render() {               
        return (
            <div>
                {this.props.isAuthenticated ? (
                    <Navbar
                        className="navbar navbar-expand-md navbar-dark bg-white fixed-top"
                        toggle={this.props.toggle}
                    >
                        <Link to="/" className={`navbar-brand ${ styles.logo }`}>
                            <img width="130" src={Logo} alt="" />
                        </Link>
                        {/*<NavbarToggler*/}
                        {/*    className="navbar-toggler d-lg-none"*/}
                        {/*    onClick={this.toggleNavbar}*/}
                        {/*/>*/}
                        {(this.props.searchMode === 'contracts' 
                        || this.props.searchMode === 'doctors' 
                        || this.props.searchMode === 'patients'
                        || this.props.searchMode === 'medOrgs'
                        || this.props.searchMode === 'archiveContracts'
                        || this.props.searchMode === 'firedDoctors'
                        || this.props.searchMode === 'admins'
                        || this.props.searchMode === 'contractTypes'
                        || this.props.searchMode === 'archiveContractTypes'
                        || this.props.searchMode === 'contractRules'
                        || this.props.searchMode === 'archiveContractRules')
                         && <Search classNames={styles.search} />}
                        {
                            <PrivateHeader
                                user={this.props.user}
                                showNavigation={this.state.showNavigation}
                                toggleDropdown={this.toggleDropdown}
                                showDropdown={this.state.showDropdown}
                                logout={this.logout}
                                toggleSidebar={this.props.toggleSidebar}
                            />
                        }
                    </Navbar>
                ) : (
                    ''
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        user: state.user,
        searchMode: state.search.mode
    }
}

export default connect(mapStateToProps)(Navigation)
