export const OPEN_MODAL = 'OPEN_MODAL';
export const CHANGE_STATUS = 'CHANGE_STATUS';
export const MAKE_MUTE = 'MAKE_MUTE';
export const FULL_SCREEN = 'FULL_SCREEN';
export const SET_CONNECTION_STATUS = 'SET_CONNECTION_STATUS';
export const SET_IS_CALL_TO_ME = 'SET_IS_CALL_TO_ME';
export const SET_CONNECTION_TYPE = 'SET_CONNECTION_TYPE';
export const TOGGLE_CAMERA = 'TOOGLE_CAMERA';
export const GET_MESSAGE = 'GET_MESSAGE';
export const SET_PHONE_BOOK = 'SET_PHONE_BOOK';
export const SET_OTHER_NUMBER = 'SET_OTHER_NUMBER';
export const SET_OTHER_USER = 'SET_OTHER_USER';

export default {
  OPEN_MODAL,
  CHANGE_STATUS,
  MAKE_MUTE,
  FULL_SCREEN,
  SET_CONNECTION_STATUS,
  SET_IS_CALL_TO_ME,
  SET_CONNECTION_TYPE,
  TOGGLE_CAMERA,
  GET_MESSAGE,
  SET_PHONE_BOOK,
  SET_OTHER_NUMBER,
  SET_OTHER_USER,       
};