import Http from '../../../utils/Http';
import {doctorsList} from './doctors-actions';
import Transformer from '~/utils/Transformer';

function transformResponse(params) {
  return Transformer.fetch(params);
}

const loadDoctorsList = async (dispatch, url = '/users/doctors' , isCamelСase = false) => {
  const response = await Http.get(url);  

  if (isCamelСase) {     
    dispatch(doctorsList(transformResponse(response.data.data.data)));
  } else {      
    dispatch(doctorsList(response.data?.data?.data || []));
  } 
};

export {loadDoctorsList};
