import {
  OPEN_MODAL,
  CHANGE_STATUS,
  MAKE_MUTE, 
  FULL_SCREEN,
  SET_CONNECTION_STATUS,
  SET_IS_CALL_TO_ME, 
  SET_CONNECTION_TYPE,
  TOGGLE_CAMERA, 
  GET_MESSAGE, 
  SET_PHONE_BOOK, 
  SET_OTHER_NUMBER, 
  SET_OTHER_USER,
} from './action-types'

const initialState = {
  isModalOpen: false,                  
  status: '',
  isMute: false,
  isFullScreen: false,
  isConnection: false, 
  isCallToMe: false,
  connectionType: '',
  isCameraOf: false, 
  message: '',
  phoneBook: null,
  otherNumber: '', 
  otherUser: {},
};

const reducer = (state = initialState, { type, payload }) => {
  switch(type) {
    case OPEN_MODAL:
      return openModal(state, payload)  
    case CHANGE_STATUS:
      return changeStatus(state, payload) 
    case MAKE_MUTE:
      return makeMute(state, payload)
    case FULL_SCREEN:
      return setFullScreen(state, payload)   
    case SET_CONNECTION_STATUS:
      return setConnectionStatus(state, payload)    
    case SET_IS_CALL_TO_ME:
      return setIsCallToMe(state, payload)
    case SET_CONNECTION_TYPE:
      return setConnectionType(state, payload) 
    case TOGGLE_CAMERA:
      return toggleCamera(state, payload)
    case GET_MESSAGE:
      return getMessage(state, payload)
    case SET_PHONE_BOOK:
      return setPhoneBook(state, payload)
    case SET_OTHER_NUMBER:
      return setOtherNumber(state, payload) 
    case SET_OTHER_USER:
      return setOtherUser(state, payload)                                           
    default:
      return state
  }
};

function openModal(state, payload) {
  return {
    ...state,
    isModalOpen: payload,
  }
}

function changeStatus(state, payload) {
  return {
    ...state,
    status: payload,
  }
}

function makeMute(state, payload) {
  return {
    ...state,
   isMute: payload,
  }
}

function setFullScreen(state, payload) {
  return {
    ...state,
   isFullScreen: payload,
  }
}

function setConnectionStatus(state, payload) {
  return {
    ...state,
   isConnection: payload,
  }
}

function setIsCallToMe(state, payload) {
  return {
    ...state,
   isCallToMe: payload,
  }
}

function setConnectionType(state, payload) {
  return {
    ...state,
   connectionType: payload,
  }
}

function toggleCamera(state, payload) {
  return {
    ...state,
   isCameraOf: payload,
  }
}

function getMessage(state, payload) {
  return {
    ...state,
   message: payload,
  }
}

function setPhoneBook(state, payload) {
  return {
    ...state,
   phoneBook: payload,
  }
}

function setOtherNumber(state, payload) {
  return {
    ...state,
   otherNumber: payload,
  }
}

function setOtherUser(state, payload) {
  return {
    ...state,
   otherUser: payload,
  }
}

export default reducer