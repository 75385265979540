import Http from '~/utils/Http';
import Transformer from '~/utils/Transformer';
import { getFullNameByUser } from '~/utils/getFullName';
import * as userActions from './store/actions';
import {toast} from 'react-toastify';
import moment from 'moment/moment';
import {fetchUser} from '../auth/service';

function transformRequest(parms) {
  return Transformer.send(parms);
}

function transformResponse(params) {
  return Transformer.fetch(params);
}

export function patientAddRequest(params) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post('/users/patients', transformRequest(params))
        .then((res) => {
          if (parseInt(res.data.result) > 0) {
            dispatch(userActions.add(transformResponse(res.data.data)));
            return resolve();
          }
        })
        .catch((err) => {
          console.log(err);
          /*
          const statusCode = err.response.status;
          const data = {
              error: null,
              statusCode,
          };

          if (statusCode === 422) {
              const resetErrors = {
                  errors: err.response.data.data,
                  replace: false,
                  searchStr: '',
                  replaceStr: '',
              };
              data.error = Transformer.resetValidationFields(resetErrors);
          } else if (statusCode === 401) {
              data.error = err.response.data.errmessage;
          }
          return reject(data);*/
        });
    });
}

export function doctorAddRequest(params, callback=null) {
  params.birthDay = moment(params.birthDay).format('YYYY-MM-DD');
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post('/users/doctors', transformRequest(params))
        .then((res) => {
          callback && callback(res);
          dispatch(userListRequest({url: '/users/doctors', params: 'fired=false'}));
          return resolve();
        })
        .catch((err) => {
          const statusCode = err.response.status;
          const data = {
            error: null,
            statusCode,
          };

          if (statusCode === 422) {
            const resetErrors = {
              errors: err.response.data.data,
              replace: false,
              searchStr: '',
              replaceStr: '',
            };
            data.error = Transformer.resetValidationFields(resetErrors);
          } else if (statusCode === 401) {
            data.error = err.response.data.errmessage;
          }
          return reject(data);
        });
    });
}

export function doctorEditRequest(params, callback=null) {
  params.birthDay = moment(params.birthDay).format('YYYY-MM-DD');
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put(`/users/doctors/${params.id}`, transformRequest(params))
        .then((res) => {
          callback && callback(res);
          dispatch(userListRequest({url: '/users/doctors', params: 'fired=false'}));
          return resolve();
        })
        .catch((err) => {
          const statusCode = err.response.status;
          const data = {
            error: null,
            statusCode,
          };

          if (statusCode === 422) {
            const resetErrors = {
              errors: err.response.data.data,
              replace: false,
              searchStr: '',
              replaceStr: '',
            };
            data.error = Transformer.resetValidationFields(resetErrors);
          } else if (statusCode === 401) {
            data.error = err.response.data.errmessage;
          }
          return reject(data);
        });
    });
}

export function userAddRequest({url='/users', params, callback=null}) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(url, transformRequest(params))
        .then((res) => {
          dispatch(userActions.add(transformResponse(res.data.data)));
          callback();
          toast('Пользователь успешно добавлен')
          return resolve();
        })
        .catch((err) => {
          const statusCode = err.response;
          const data = {
            error: null,
            statusCode,
          };

          if (statusCode === 422) {
            const resetErrors = {
              errors: err.response.data.data,
              replace: false,
              searchStr: '',
              replaceStr: '',
            };
            data.error = Transformer.resetValidationFields(resetErrors);
          } else if (statusCode === 401) {
            data.error = err.response.data.errmessage;
          }
          return reject(data);
        });
    });
}

export function userEditRequest(id) {
  return (dispatch) => {
    Http.get(`/users/${id}`)
      .then((res) => {
        dispatch(userActions.add(transformResponse(res.data.data)));
      })
      .catch((err) => {
        // TODO: handle err
        console.error(err.response);
      });
  };
}

export function userFetchRequest(id) {
  return (dispatch) => {
    Http.get(`/users/${id}`)
      .then((res) => {
        dispatch(userActions.add(transformResponse(res.data.data)));
      })
      .catch((err) => {
        // TODO: handle err
        console.error(err.response);
      });
  };
}

export function userListRequest({pageNumber = 1, url = '/users', params = ''}) {
  return (dispatch) => {

    if (pageNumber > 1) {
      url = url + `?page=${pageNumber}`;
    }
    
    if (params && pageNumber > 1) {
      url = url + `&${params}`
    } else if (params && pageNumber === 1) {
      url = url + `?${params}`
    }

    Http.get(url)
      .then((res) => {        
        dispatch(userActions.list(transformResponse(res.data.data)));
      })
      .catch((err) => {
        // TODO: handle err
        console.error(err.response);
      });
  };
}

export function typeListRequestOptions({
  pageNumber = 1,
  url = '/contract_types',
}) {
  let options;
  options = [];
  Http.get(url)
    .then((res) => {
      res.data.data.data.map((item) => {
        return options.push({value: item.id, label: item.name});
      });
    })
    .catch((err) => {
      // TODO: handle err
      return console.error(err.response);
    });
  return options;
}

export function timezoneOptions() {
  return [
    {
      label: 'UTC+02',
      value: 'UTC+02',
    },
    {
      label: 'UTC+03',
      value: 'UTC+03',
    },
    {
      label: 'UTC+04',
      value: 'UTC+04',
    },
    {
      label: 'UTC+05',
      value: 'UTC+05',
    },
    {
      label: 'UTC+06',
      value: 'UTC+06',
    },
    {
      label: 'UTC+07',
      value: 'UTC+07',
    },
    {
      label: 'UTC+08',
      value: 'UTC+08',
    },
    {
      label: 'UTC+09',
      value: 'UTC+09',
    },
    {
      label: 'UTC+10',
      value: 'UTC+10',
    },
    {
      label: 'UTC+11',
      value: 'UTC+11',
    },
    {
      label: 'UTC+12',
      value: 'UTC+12',
    },
  ];
}

export async function userListRequestOptions({pageNumber = 1, url = '/users'}) {
  let options;
  options = [];
  await Http.get(url)
    .then((res) => {
      res.data.data.data.map((item, i) => {
        options.push({
          value: item.id,
          label: 
          getFullNameByUser(transformResponse(item)) +
            ' (' +
            item.email +
            ')',
        });
      });
    })
    .catch((err) => {
      // TODO: handle err
      console.error(err.response);
    });
  return options;
}

export function userRemoveRequest(id, prevId, successCallback) {
  return (dispatch) => {
    (prevId
      ? Http.delete(`/users/${id}?prev_id=${prevId}`)
      : Http.delete(`/users/${id}`)
    )
      .then(() => {
        dispatch(userActions.remove(id));
        toast('Пользователь успешно удален');
        successCallback && successCallback();
      })
      .catch((err) => {
        // TODO: handle err
        console.error(err.response);
        toast(err.response.data.errmessage);
      });
  };
}

//for active toggle
export function userListUpdateRequest(params, callback=null) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.patch(`/users/${params.id}`, transformRequest(params))
        .then((res) => {
          callback && callback(res)
          dispatch(userActions.add(transformResponse(res.data.data)));
          return resolve();
        })
        .catch((err) => {
          const statusCode = err.response.status;
          const data = {
            error: null,
            statusCode,
          };

          if (statusCode === 422) {
            const resetErrors = {
              errors: err.response.data.data,
              replace: false,
              searchStr: '',
              replaceStr: '',
            };
            data.error = Transformer.resetValidationFields(resetErrors);
          } else if (statusCode === 401) {
            data.error = err.response.data.errmessage;
          }
          return reject(data);
        });
    });
}

export const returnFromVacation = (id, callback) => {
  return async (dispatch) =>
    await Http.get(`/users/fromvacation/${id}`)
      .then(() => {
        toast('Врач возвращен из отпуска');
        dispatch(userFetchRequest(id));
        callback && callback();
      })
      .catch((e) => console.error(e));
};

export const editUserData = (id, data, callback) => async (dispatch) =>
  await Http.put(`/users/${id}`, data)
    .then(() => {
           
      toast('Данные успешно изменены');
      dispatch(fetchUser());
      callback && callback();
    })
    .catch((err) => {
      const statusCode = err.response.status;
      if (statusCode === 422) {
        toast('Выбранная почта занята');
      }
    }) 
