import Model from '../../utils/Model'

class Medorg extends Model {
  constructor(props) {
    super(props)

    this.initialize(props)
  }

  initialize(props) {
    super.initialize(props)
    this.id = props.id || ''
    this.name = props.name || ''
    this.phone = props.phone || ''
    this.address = props.address || ''
    this.photo = props.photo || ''
    this.contactUser = props.contactUser || ''
    this.contactUserPhone = props.contactUserPhone || ''
    this.timezone = props.timezone || ''
    this.active = props.active || false
    this.contractsAllCount = props.contractsAllCount || 0
    this.contractsActiveCount = props.contractsActiveCount || 0
    this.contractsArchiveCount = props.contractsArchiveCount || 0
    this.doctorsAllCount = props.doctorsAllCount || 0
    this.patientsAllCount = props.patientsAllCount || 0
  }
}

export default Medorg
