// import lib
import { lazy } from 'react'

export default [
  {
    path: '/login',
    exact: true,
    component: lazy(() => import('./pages/login/index')),
  },
  {    
    path: '/api/v1/users/activate/:id/:checkword',
    exact: true,
    component: lazy(() => import('./pages/setpassword/index')),
  },
  {
    path: '/recovery',
    exact: true,
    component: lazy(() => import('./pages/recovery')),
  },
  {
    path: '/reset',
    exact: true,
    component: lazy(() => import('./pages/reset')),
  },
  // {
  //   path: '/register',
  //   exact: true,
  //   component: lazy(() => import('./pages/register/index')),
  // },
]
