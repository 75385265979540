// import lib
import { lazy } from 'react'

export default [

  {
    path: '/admin',
    exact: true,
    auth: true,
    admin: true,
    breadcrumbs: [
      {
        'href': "/admin",
        'label' : 'Главная'
      },
    ],
    title: 'Данные о вашем медицинском учреждении',
    component: lazy(() => import('./pages/med_org_view/index')),
  },
  {
    path: '/admin/edit',
    exact: true,
    auth: true,
    admin: true,
    breadcrumbs: [
      {
        'href': "/admin",
        'label' : 'Главная'
      },
      {
        'href': "/admin/edit",
        'label' : 'Редактирование медицинского учреждения'
      },
    ],
    title: 'Изменение данных о Вашем медицинском учреждении',
    component: lazy(() => import('./pages/med_org_edit/index')),
  },
  //contracts
  /*{
    path: '/admin/contracts/create',
    exact: true,
    auth: true,
    component: lazy(() => import('./pages/contracts_add/index')),
  },*/
 /*  {
    path: '/admin/contracts/:id/edit',
    exact: true,
    auth: true,
    admin: true,
    component: lazy(() => import('./pages/contracts_edit/index')),
    breadcrumbs: [
      {
        'href': "/admin",
        'label' : 'Главная'
      },
      {
        'href': "/admin/contracts",
        'label' : 'Договоры'
      },
      {
        'href': "",
        'label' : 'Редактирование договора'
      },
    ],
    title: 'Редактирование договора',
  }, */
  {
    path: '/admin/contracts',
    exact: true,
    auth: true,
    admin: true,
    breadcrumbs: [
      {
        'href': "/admin",
        'label' : 'Главная'
      },
      {
        'href': "/admin/contracts",
        'label' : 'Договоры'
      },
    ],
    title: 'Активные договоры',
    searchMode: 'contracts',
    exact: true,
    auth: true,
    admin: true,
    component: lazy(() => import('./pages/contracts_list/index')),
  },
  {
    path: '/admin/contracts/archive',
    exact: true,
    auth: true,
    admin: true,
    breadcrumbs: [
      {
        'href': "/admin",
        'label' : 'Главная'
      },
      {
        'href': "/admin/contracts",
        'label' : 'Договоры'
      },
      {
        'href': "/admin/contracts/archive",
        'label' : 'Архивные'
      },
    ],
    exact: true,
    auth: true,
    admin: true,
    title: 'Архивные договоры',
    searchMode: 'archiveContracts',
    component: lazy(() => import('./pages/contracts_list_archive/index')),
  },
  {
    path: '/admin/contracts/:id',
    exact: true,
    auth: true,
    admin: true,
    breadcrumbs: [
      {
        'href': "/admin",
        'label' : 'Главная'
      },
      {
        'href': "/admin/contracts",
        'label' : 'Договоры'
      },
      {
        'href': "/admin/contracts/:id",
        'label' : "Просмотр договора"
      },
    ],
    title: 'Title',
    exact: true,
    auth: true,
    admin: true,
    component: lazy(() => import('./pages/contracts_view/index')),
  },
    //users
  {
    path: '/admin/users/create',
    exact: true,
    auth: true,
    admin: true,
    breadcrumbs: [
        {
          'href': "/",
          'label' : 'Главная'
        },
    ],
    auth: true,
    component: lazy(() => import('./pages/users_add/index')),
  },
  {
    path: '/admin/users/detail/:id',
    exact: true,
    auth: true,
    admin: true,
    breadcrumbs: [
      {
        'href': "/",
        'label' : 'Главная'
      },
      {
        'href': "/",
        'label' : 'Просмотр пользователя'
      },
    ],
    title: '',
    component: lazy(() => import('./pages/user_view/index')),
  },
  {
    path: '/admin/users/:id/edit',
    exact: true,
    auth: true,
    admin: true,
    component: lazy(() => import('./pages/users_edit/index')),
  },
  {
    path: '/admin/users',
    exact: true,
    auth: true,
    admin: true,
    component: lazy(() => import('./pages/users_list/index')),
  },
  {
    path: '/admin/users/admins',
    exact: true,
    auth: true,
    admin: true,
    searchMode: 'admins',
    breadcrumbs: [
      {
        'href': "/admin",
        'label' : 'Главная'
      },
      {
        'href': "/admin/settings",
        'label' : 'Настройки'
      },
      {
        'href': "",
        'label' : 'Управление администраторами'
      },
    ],
    title: 'Управление администраторами',
    component: lazy(() => import('./pages/admins/index')),
  },
  {
    path: '/admin/reports',
    exact: true,
    auth: true,
    admin: true,
    breadcrumbs: [
      {
        'href': "/",
        'label' : 'Главная'
      },
      {
        'href': "",
        'label' : 'Отчёты'
      },
    ],
    title: 'Формирование отчетных документов',
    component: lazy(() => import('./pages/reports')),
  },
  {
    path: '/admin/users/doctors',
    exact: true,
    auth: true,
    admin: true,
    searchMode: 'doctors',
    breadcrumbs: [
      {
        'href': "/",
        'label' : 'Главная'
      },
      {
        'href': "",
        'label' : 'Врачи'
      },
    ],
    title: 'Врачи',
    component: lazy(() => import('./pages/doctors/index')),
  },
  {
    path: '/admin/users/doctors/fired',
    exact: true,
    auth: true,
    admin: true,
    searchMode: 'firedDoctors',
    breadcrumbs: [
      {
        'href': "/",
        'label' : 'Главная'
      },
      {
        'href': "",
        'label' : 'Уволенные врачи'
      },
    ],
    title: 'Уволенные врачи',
    component: lazy(() => import('./pages/doctors_fired/index')),
  },
  {
    path: '/admin/users/patients',
    exact: true,
    auth: true,
    admin: true,
    searchMode: 'patients',
    breadcrumbs: [
      {
        'href': "/",
        'label' : 'Главная'
      },
      {
        'href': "",
        'label' : 'Пациенты'
      },
    ],
    title: 'Пациенты',
    component: lazy(() => import('./pages/patients/index')),
  },
    //types
  {
    path: '/admin/contract_types/:id/edit',
    exact: true,
    auth: true,
    admin: true,
    component: lazy(() => import('./pages/types_edit/index')),
  },
  {
    path: '/admin/contract_types',
    exact: true,
    auth: true,
    admin: true,
    searchMode: 'contractTypes',
    component: lazy(() => import('./pages/types_list/index')),
    breadcrumbs: [
      {
        'href': "/admin",
        'label' : 'Главная'
      },
      {
        'href': "/admin/settings",
        'label' : 'Настройки'
      },
      {
        'href': "/admin/contract_types",
        'label' : 'Типы договоров'
      },
    ],
    title: 'Настройки типов договоров',
  },
  {
    path: '/admin/contract_types/archive',
    exact: true,
    auth: true,
    admin: true,
    searchMode: 'archiveContractTypes',
    breadcrumbs: [
      {
        'href': "/admin",
        'label' : 'Главная'
      },
      {
        'href': "/admin/settings",
        'label' : 'Настройки'
      },
      {
        'href': "/admin/contract_types",
        'label' : 'Типы договоров'
      },
      {
        'href': "/admin/contract_types/archive",
        'label' : 'Архивные типы договоров'
      },
    ],
    title: 'Просмотр архивных типов договоров',
    component: lazy(() => import('./pages/types_list_archive/index')),
  },
  {
    path: '/admin/contract_types/create',
    exact: true,
    auth: true,
    admin: true,
    component: lazy(() => import('./pages/types_add/index')),
  },
    //rules
  {
    path: '/admin/contract_rules',
    exact: true,
    auth: true,
    admin: true,
    searchMode: 'contractRules',
    breadcrumbs: [
      {
        'href': "/admin",
        'label' : 'Главная'
      },
      {
        'href': "/admin/settings",
        'label' : 'Настройки'
      },
      {
        'href': "/admin/contract_rules",
        'label' : 'Правила обработки сообщений'
      },
    ],
    title: 'Настройки правил обработки сообщений',
    component: lazy(() => import('./pages/rules_list/index')),
  },
  {
    path: '/admin/contract_rules/archive',
    exact: true,
    auth: true,
    admin: true,
    searchMode: 'archiveContractRules',
    breadcrumbs: [
      {
        'href': "/admin",
        'label' : 'Главная'
      },
      {
        'href': "/admin/settings",
        'label' : 'Настройки'
      },
      {
        'href': "/admin/contract_rules",
        'label' : 'Правила обработки сообщений'
      },
      {
        'href': "/admin/contract_rules/archive",
        'label' : 'Архивные'
      },
    ],
    title: 'Просмотр архивных правил обработки сообщений',
    component: lazy(() => import('./pages/rules_list_archive/index')),
  },
  {
    path: '/admin/contract_rules/create',
    exact: true,
    auth: true,
    admin: true,
    breadcrumbs: [
      {
        'href': "/admin",
        'label' : 'Главная'
      },
      {
        'href': "/admin/contract_rules",
        'label' : 'Правила обработки'
      },
      {
        'href': "/admin/contract_rules/create",
        'label' : 'Создание правила обработки'
      },
    ],
    title: 'Настройки',
    component: lazy(() => import('./pages/rules_add/index')),
  },
  {
    path: '/admin/settings',
    exact: true,
    auth: true,
    admin: true,
    breadcrumbs: [
      {
        'href': "/admin",
        'label' : 'Главная'
      },
      {
        'href': "/admin/settings",
        'label' : 'Настройки'
      },
    ],
    title: 'Настройки',
    component: lazy(() => import('./pages/settings/index')),
  },
  {
    path: '/admin/*',
    exact: true,
    auth: true,
    admin: true,
    redirect: true,
    component: lazy(() => import('./pages/med_org_view/index')),
  },
]
