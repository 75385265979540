import {
  MED_ADD,
  MED_UPDATE,
  MED_REMOVE,
  MED_LIST,
  MED_GET,
} from './action-types'
import Medorg from "../Medorg";

const initialState = Object.assign({}, new Medorg({}), {medorgs: {}})

const reducer = (state = initialState, { type, payload = null }) => {
  switch(type) {
    case MED_ADD:
      return add(state, payload)
    case MED_GET:
      return get(state, payload)  
    case MED_UPDATE:
      return update(state, payload)
    case MED_REMOVE:
      return remove(state, payload)
    case MED_LIST:
      return list(state, payload)
    default:
      return state
  }
}
function add(state, medorg) {  
  return Object.assign(state, medorg);
}

function get(state, payload) {  
  return {
    ...state, ...payload
  }
}

function update(state, payload) {
  return {
    ...state, ...payload
  }
}

function remove(state, id) {
  const data = state.data.filter(obj => obj.id !== id)

  return Object.assign({}, state, { data })
}

function list(state, payload) {
  return {
    ...state,
    medorgs: {...payload}
  }
}

export default reducer
