import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ReeValidate from 'ree-validate';
import PropTypes from 'prop-types';

import Transformer from '~/utils/Transformer';
import checkDownloadPermission from '~/utils/checkDownloadPermission';
import formatFileSize from "~/utils/formatFileSize";

import { sendMessageRequest } from './service';

import ModalFeedBack from '../../modals/ModalFeedBack/ModalFeedBack';
import FileNameItem from '../../modals/ModalFeedBack/FileNameList/FileNameItem/FileNameItem';
import ModalBlock from '../../modals/ModalBlock/ModalBlock';

const Support = ({isAuthorized, isOpen, onClose, instructionLink}) => {
  
  const validatorModalFeedBack = new ReeValidate.Validator({		
		email: isAuthorized ? '' : 'required|email',    
    name: isAuthorized ? '' : 'required|min:1',	
    title: 'required|min:1',
    message: 'required|min:1',
    files: '',		
	});

  const [state, setState] = useState({
    email: '', 
    name: '',
    title: '',
    message: '',
    files: [],
  });

  const [errorsModalFeedBack, setErrorsModalFeedBack] = useState({
    errors: validatorModalFeedBack.errors,  
  });
  
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [serverResponce, setServerResponce] = useState('');
  
  const dispatch = useDispatch();

  let nameElements = [];

	for (let i = 0; i < state.files.length; i++) {
		nameElements.push(<FileNameItem
			fileName={state.files[i].name}
			fileSize={formatFileSize(state.files[i].size)}
			key={i}
			id={i}
			deleteFile={deleteFile}
		/>)
	}
  
  function deleteFile(e) { 
		const target = e.target; 				
		setState({
			...state,
			files: [...state.files.slice(0, +target.id), ...state.files.slice(+target.id + 1)	]
		});
	}

  const handleChange = (event) => {
    const {errors} = validatorModalFeedBack;  
    const {name, value} = event.target;
    setState({...state, [name]: value});

    errors.remove(name);

    validatorModalFeedBack.validate(name, value).then(() => {
      setErrorsModalFeedBack({
        ...errorsModalFeedBack,
        errors
      });
    });
  };

  const handleSubmit = () => {
    let item = state;
    if (isAuthorized) {
      delete item.email;
      delete item.name;
    }
    const {errors} = validatorModalFeedBack; 

    validatorModalFeedBack.validateAll(item).then((success) => {
      if (success) {              
        dispatch(sendMessageRequest(item, (data) => {
          onClose();
          setServerResponce(() => data);
          setIsModalSuccessOpen(true);
          setTimeout(() => setIsModalSuccessOpen(false), 3000); 
          setState({
            email: '', 
            name: '',
            title: '',
            message: '',
            files: [], 
          }) 
        }))
      } else {
        setErrorsModalFeedBack({
          ...errorsModalFeedBack,
          errors
        });
      }
    });     
  }; 

  const uploadFile = (e) => {
		e.preventDefault();		
		const files = Array.from(e.target.files);
		if (checkDownloadPermission(files, state.files, true)) {
			for (let i = 0; i < files.length; i++) {       	
				setState({
					...state,
					files: [...state.files, ...files ]
				});					
			}	
		}		
	};

  const modalClose = () => {
    onClose();
    setState({
      email: '', 
      name: '',
      title: '',
      message: '',
      files: [], 
    })  
  }

  const props = {
    onClose: modalClose,   
    isAuthorized,    
    uploadFile,
    isOpen,
    state,
    handleChange,
    errorsModalFeedBack,
    nameElements,
    handleSubmit,    
    instructionLink,
  };

  return (
    <>
      <ModalFeedBack {...props} />
      <ModalBlock onClose={() => setIsModalSuccessOpen(false)} isOpen={isModalSuccessOpen} title="Успех">
        <div style={{textAlign: 'center'}}>{serverResponce}</div>
      </ModalBlock>
    </>
  );
};

Support.propTypes = {
  children: PropTypes.node,  
  isAuthorized: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,  
  onClose: PropTypes.func.isRequired,
  instructionLink: PropTypes.string 
};

export default Support;