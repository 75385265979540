/* ============
 * Actions for the user module
 * ============
 *
 * The actions that are available on the
 * user module.
 */

import {
  USER_UPDATE,
  USER_ADD, USER_REMOVE, USER_LIST,
} from './action-types';


export function add(payload) {
  return {
    type: USER_ADD,
    payload
  }
}

export function remove(payload) {
  return {
    type: USER_REMOVE,
    payload
  }
}

export function list(payload) {
  return {
    type: USER_LIST,
    payload
  }
}

export function update(payload) {
  return {
    type: USER_UPDATE,
    payload
  }
}
