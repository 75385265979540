import React from 'react'
import PropTypes from 'prop-types'
import styles from './InputSlot.module.scss'
import classNames from 'classnames'

const InputSlot = ({
    name,
    children,
    label,
    bottom,
    status,
    required,
    syntRequired,
    className,
    isBottomErr,
}) => {
    return (
        <div className={classNames(styles.block, styles[status], className)}>
            {label && (
                <label className={styles.label} htmlFor={name}>
                    {label && <div className={styles.top}>{label}</div>}
                    {(required || syntRequired) && <div className={styles.required}>*</div>}
                </label>
            )}
            {children}
            <div className={`${styles.bottom} ${isBottomErr ? styles.errors : ''}`}>{bottom}</div>
        </div>
    )
}

InputSlot.defaultProps = {
    type: 'text',
    required: false,
    isSynthetic: true,
    syntRequired: false,
}

InputSlot.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    bottom: PropTypes.string,
    className: PropTypes.string,
    required: PropTypes.bool,
    syntRequired: PropTypes.bool,
    errors: PropTypes.bool,
    status: PropTypes.oneOf(['success', 'error', 'default']),
}

export default InputSlot
