import Http from '../../utils/Http'
import Transformer from '../../utils/Transformer'
import * as userActions from './store/actions'
import {toast} from "react-toastify";
import ApiService from '../../utils/ApiService';

export function userUpdateRequest(params) {    
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.patch(`/users/${params.id}`, Transformer.send(params))
                .then(res => {
                    dispatch(userActions.userUpdate(Transformer.fetch(res.data.data)))
                    // toast('Персональная информация успешно обновлена')
                    return resolve()
                })
                .catch((err) => {
                    const statusCode = err.response.status;
                    const data = {
                        error: null,
                        statusCode,
                    };

                    if (statusCode === 422) {
                        const resetErrors = {
                            errors: err.response.data.data,
                            replace: false,
                            searchStr: '',
                            replaceStr: '',
                        };
                        data.error = Transformer.resetValidationFields(resetErrors);
                    } else if (statusCode === 401) {
                        data.error = err.response.data.errmessage;
                    }
                    return reject(data);
                })
        })
    )
}

export function userPatchRequest(id, data, callback, isNotify = true) {  
    return dispatch => {      
        new ApiService().put(`users/${id}`, Transformer.send(data))
            .then(() => {
                isNotify && toast('Данные успешно изменены');
                dispatch(userActions.userUpdate(data))
                callback && callback();    
            })
            .catch((err) => {
                const statusCode = err.response.status;
                if (statusCode === 422) {                  
                  toast(err.response.data.errmessage);
                }
              }) 
    }    
};

export function userUploadFileRequest(event, callback) {
    const {name, files} = event.target;
    const formData = new FormData();
    formData.append('file', files[0]);
    Http.post(`/users/uploadfile`, formData, {
      headers: {'content-type': 'multipart/form-data'},
    })
      .then((res) => {  

        if (res.data.data.path) {                       
            callback && callback(name, res.data.data.path);            
        }
      })
      .catch(() => {
        toast('Файл не загружен');
      });
};

export function userRemoveFileRequest(path, callback) {
    Http.delete(`/users/removefile?path=${path}`)
      .then(() => {                               
        callback && callback();       
      })
      .catch(() => {
        toast('Файл не удален');
      });
};


