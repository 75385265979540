import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import styles from './RootModal.module.scss'

Modal.setAppElement('#root');

const RootModal = ({ children, isOpen, onClose, shouldCloseOnOverlayClick=null}) => {
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0',
            transform: 'translate(-50%, -50%)',
        },
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            overlayClassName={styles.overlay}
            style={customStyles}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        >
            {children}
        </Modal>
    )
}

RootModal.propTypes = {
    children: PropTypes.node,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
}

export default RootModal
