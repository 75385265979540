import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import styles from './Search.module.scss'
import { ReactComponent as IconSearch } from '../../icons/IconSearch.svg'
import { setSearchValue } from '../../store/modules/search/search-actions'

const Search = ({ classNames }) => {
    const dispatch = useDispatch()
    const value = useSelector((store) => store.search.value)
    const typedFn = useSelector((store) => store.search.searchFn)
    const placeholder = useSelector((store) => store.search.placeholder)

    const changeInput = (value) => {        
        dispatch(setSearchValue(value))
        if (typedFn && typeof typedFn === 'function') {
            typedFn(value)
        }
    }

    return (
        <div className={classnames(styles.wrapper, classNames)}>
            <div className={styles.icon}>
                <IconSearch />
            </div>
            <input
                type="text"
                placeholder={placeholder ?? 'Что вы ищите?'}
                className={styles.input}
                value={value}                                
                onChange={({ target: { value } }) => changeInput(value)}                                                            
            />
        </div>
    )
}

export default Search
