import {
  OPEN_MODAL,
  CHANGE_STATUS,
  MAKE_MUTE,
  FULL_SCREEN,
  SET_CONNECTION_STATUS,
  SET_IS_CALL_TO_ME,
  SET_CONNECTION_TYPE,
  TOGGLE_CAMERA, 
  GET_MESSAGE,
  SET_PHONE_BOOK,
  SET_OTHER_NUMBER,
  SET_OTHER_USER, 
} from './action-types';

export function openModal(payload) {
  return {
    type: OPEN_MODAL,
    payload
  }
}

export function changeStatus(payload) {
  return {
    type: CHANGE_STATUS,
    payload
  }
}

export function makeMute(payload) {
  return {
    type: MAKE_MUTE,
    payload
  }
}

export function setFullScreen(payload) {
  return {
    type: FULL_SCREEN,
    payload
  }
}

export function setConnectionStatus(payload) {
  return {
    type: SET_CONNECTION_STATUS,
    payload
  }
}

export function setIsCallToMe(payload) {
  return {
    type: SET_IS_CALL_TO_ME,
    payload
  }
}

export function setConnectionType(payload) {
  return {
    type: SET_CONNECTION_TYPE,
    payload
  }
}

export function toggleCamera(payload) {
  return {
    type: TOGGLE_CAMERA,
    payload
  }
}

export function getMessage(payload) {
  return {
    type: GET_MESSAGE,
    payload
  }
}

export function setPhoneBook(payload) {
  return {
    type: SET_PHONE_BOOK,
    payload
  }
}

export function setOtherNumber(payload) {
  return {
    type: SET_OTHER_NUMBER,
    payload
  }
}

export function setOtherUser(payload) {
  return {
    type: SET_OTHER_USER,
    payload
  }
}