import React from 'react'
import PropTypes from 'prop-types'
import styles from './TextArea.module.scss'

const TextArea = ({
    name,
    rows,
    value,
    label,
    placeholder,
    disabled,
    required,
    syntRequired,
    isSynthetic,
    onChange,
    bottom,
}) => {
    const change = (e) => {
        if (!isSynthetic) {
            return onChange(e.target.value)
        }
        return onChange(e)
    }

    return (
        <div className={styles.block}>
            {label && (
                <label className={styles.label} htmlFor={name}>
                    {label && <div className={styles.top}>{label}</div>}
                    {(required || syntRequired) && <div className={styles.required}>*</div>}
                </label>
            )}
            <textarea
                rows={rows}
                className={styles.input}
                name={name}
                disabled={disabled}
                required={required}
                value={value}
                placeholder={placeholder}
                onChange={change}
            />
            <div className={styles.bottom}>{bottom}</div>
        </div>
    )
}

TextArea.defaultProps = {
    type: 'text',
    required: false,
    isSynthetic: true,
}

TextArea.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    isSynthetic: PropTypes.bool,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func,
    bottom: PropTypes.string,
    syntRequired: PropTypes.bool,
}

export default TextArea
