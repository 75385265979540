import React, { Suspense, useEffect } from "react";
import PropTypes from 'prop-types';
import {Route, Redirect} from 'react-router-dom';
import {connect, useDispatch} from 'react-redux';
import Http from '../utils/Http';
import Transformer from '../utils/Transformer';
import setRedirectPath from "../utils/setRedirectPath";
import * as authActions from '../modules/auth/store/actions';
import {setSearchMode} from '../store/modules/search/search-actions';

const PrivateRoute = ({
  component: Component,
  userData,
  isAuthenticated,
  ...rest
}) => {  
  const {searchMode} = rest;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSearchMode(searchMode));
  }, [])
  
  return (
    <Route
      {...rest}
      render={(props) => {                     
        return (
          <Suspense fallback={<div>{/* Загрузка... */}</div>}>
            {isAuthenticated ? (
              checkRules(Component, userData, props, rest)
            ) : (
              <Redirect
                to={{
                  pathname: '/',
                  state: {from: props.location},
                }}
              />
            )}
          </Suspense>
        );
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.object.isRequired,
  location: PropTypes.object,
  isAuthenticated: PropTypes.bool.isRequired,
  userData: PropTypes.object,
};

function checkRulesBool(rest, userData) {
  if (rest.admin) if (!userData.isAdmin) return true;
  if (rest.patient) if (!userData.isPatient) return true;
  if (rest.doctor) if (!userData.isDoctor) return true;
  if (rest.sysAdmin) if (!userData.isSystemAdmin) return true;
  return false;
}

function checkRules(Component, userData, props, rest) {   
  if (!userData.id) {
    Http.get('/auth/user')
      .then((res) => {
        const data = Transformer.fetch(res.data.data);
        authActions.authUser(data);
        if (checkRulesBool(rest, data) || rest.redirect)
          return (
            <Redirect
              to={{
                pathname: setRedirectPath(userData),
                state: {from: props.location},
              }}
            />
          );
        else return <Component {...props} {...rest} />;
      })
      .catch((err) => {
        return (
          <Redirect
            to={{
              pathname: setRedirectPath(userData),
              state: {from: props.location},
            }}
          />
        );
      });
  } else {    
    if (checkRulesBool(rest, userData) || rest.redirect)
      return (
        <Redirect
          to={{
            pathname: setRedirectPath(userData),
            state: {from: props.location},
          }}
        />
      );
    else {      
      return <Component {...props} {...rest} />;
    }
  }
}

// Retrieve data from store as props
function mapStateToProps(store) {
  return {
    isAuthenticated: store.auth.isAuthenticated,
    userData: store.user,
  };
}

export default connect(mapStateToProps)(PrivateRoute);
