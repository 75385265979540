export const CHAT_LIST = 'CHAT_LIST';
export const UPDATE_LIST = 'UPDATE_LIST';
export const MESSAGE_ADD = 'MESSAGE_ADD';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const SEARCH_LIST = 'SEARCH_LIST';
export const GET_ID = 'GET_ID';
export const SET_SEARCH = 'SET_SEARCH';
export const ADD_PAGE_NUMBER ='ADD_PAGE_NUMBER';
export const SET_NEW_OTHER_USER_MESSAGE ='SET_NEW_OTHER_USER_MESSAGE';

export default {
  CHAT_LIST,
  UPDATE_LIST,
  MESSAGE_ADD,
  UPDATE_STATUS,
  SEARCH_LIST,
  GET_ID,
  SET_SEARCH,
  ADD_PAGE_NUMBER,
  SET_NEW_OTHER_USER_MESSAGE,  
};