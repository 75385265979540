import ApiService from '~/utils/ApiService';
import Transformer from '~/utils/Transformer';

import * as sipActions from './store/actions';


let sipStack;
let registerSession;
export let callSession;
let sessionsOptions;

export function setOptions(videoRemote, videoLocal, audioRemote, callback=null) {
  sessionsOptions = {
    video_local: videoLocal,
    video_remote: videoRemote,
    audio_remote: audioRemote,
    bandwidth: { audio: 128, video: 1024},    
    events_listener: { events: '*', listener: (e) => eventsListener(e, callback) } // optional: '*' means all events
  }     
}

export function readyCallback({myNumber, callback=null}) {  
  createSipStack({myNumber, callback}); // see next section
};

export function errorCallback(e) {
  console.error('Failed to initialize the engine: ' + e.message);
};

function eventsListener(e, callback) {  
  
  if(e.type == 'started'){       				
    login();
  }	else if(e.type == 'i_new_call'){ // incoming audio/video call
    callback && callback(e.type)    
    if (callSession) {      
      e.newSession.hangup(); 
    } else {
      callSession = e.newSession;
      callSession.setConfiguration(sessionsOptions);
    }     
  } else if((e.type == 'connected' || e.type == 'connecting') && e.session == registerSession){    
    callback && callback(e.type)    
  } else if (e.type == 'm_permission_accepted') {
      
  } else if (e.type == 'terminated' || e.type == 'terminating') {  
    callback && callback(e.type) 
    callSession = null;
  } else if (e.type == 'i_ao_request') {
    callback && callback(e.type) 
  } else if (e.type == 'm_early_media') {
    callback && callback(e.type) 
  } else if (e.type === 'm_stream_video_remote_added') {    
    callback && callback(e.type) 
  } else if (e.type === 'i_new_message') {
    //second argument for message getting. search global in project - 'message getting' 
    callback && callback(e.type, e)                
  }
}

function createSipStack({myNumber, callback}){   
  sipStack = new window.SIPml.Stack({
    realm: 'voip72.vc-talk2doc.ru',
    impi: myNumber,
    impu: `sip:${myNumber}@voip72.vc-talk2doc.ru`,
    password: '123test',
    display_name: myNumber,
    websocket_proxy_url: 'wss://voip72.vc-talk2doc.ru:8089/ws',   
    events_listener: { events: '*', listener: (e) => eventsListener(e, callback) },
    // ice_servers: [{url: 'stun:stun.chathelp.ru:3478' },{url:'stun:stun.comtube.ru:3478'}],
  });	
  sipStack.start();			
}

function login(callback=null){
  registerSession = sipStack.newSession('register', {
      events_listener: { events: '*', listener: (e) => eventsListener(e, callback) } // optional: '*' means all events
  });
  registerSession.register();
}
          
export function sipMakeCall({connectionType, abonentNumber}) { 
  if (abonentNumber) {
    callSession = sipStack.newSession(connectionType, sessionsOptions);
    callSession.call(abonentNumber);  
  } else {
    console.error('У юзера нет номера SIP')
  }
  
}

export function sipHangUp(callback=null) {
  if (callSession) {      
    callSession.hangup({ events_listener: { events: '*', listener: (e) => eventsListener(e, callback) } });
    callSession = null;
    registerSession = null;
  }
}

export function sipAcceptCall() {   
  callSession.accept(sessionsOptions); // e.newSession.reject() to reject the call  
}

// Mute or Unmute the call
export function sipToggleMute(callback) {
  if (callSession) {
    let i_ret;      
    let bMute = !callSession.bMute; 
    i_ret = callSession.mute('audio', bMute);
      if (i_ret != 0) {        
        return;
      }     
    callSession.bMute = bMute;
    callback(bMute);      
  }
}

export function sipToggleCamera(callback, bool) {
  
  if (callSession) {
    
    if (bool===true || bool===false) {
      let i_ret; 
      i_ret = callSession.mute('video', bool);
      
      if (i_ret != 0) {        
        return;
      }     
      callSession.bMute = bool;
      callback(bool);      
    } else {
      let i_ret;      
      let bMute = !callSession.bMute; 
      i_ret = callSession.mute('video', bMute);
      
      if (i_ret != 0) {        
        return;
      }     
      callSession.bMute = bMute;
      callback(bMute);  
    }
        
  }
}

export function fullScreen({isFullScreen, callback=null}) {
  let newStatus = !isFullScreen;    
  callback(newStatus);  
};

export function phoneBookRequest () {
  return dispatch  => {
    new ApiService().getPhoneBook()
      .then(res => {        
        dispatch(sipActions.setPhoneBook(Transformer.fetch(res)))  
      })
  }  
}