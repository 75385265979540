// import lib
import { lazy } from 'react'

const ROUTE_PREFIX = '/doctor'

export default [
  {
    path: `${ ROUTE_PREFIX }`,
    exact: true,
    auth: true,        
    doctor: true,
            
    breadcrumbs: [
      {
        'href': `${ ROUTE_PREFIX }`,
        'label' : 'Главная'
      },
    ],
    title: '',
    component: lazy(() => import('./pages/DialogsPage/index')),
  },
  {
    path: `${ ROUTE_PREFIX }/profile`,
    exact: true,
    auth: true,
    doctor: true,
    breadcrumbs: [
      {
        'href': `${ ROUTE_PREFIX }`,
        'label' : 'Главная'
      },
      {
        'href': `/${ ROUTE_PREFIX }/profile`,
        'label' : 'Профиль'
      },
    ],
    title: '',
    component: lazy(() => import('./pages/ProfilePage/index')),    
  },
  {
    path: `${ ROUTE_PREFIX }/*`,
    exact: true,
    auth: true,
    doctor: true,
    redirect: true,
    component: lazy(() => import('./pages/DialogsPage/index')),      
  },  
]
