import Model from '../../utils/Model'

class Type extends Model {
    constructor(props) {
        super(props)

        this.initialize(props)
    }

    initialize(props) {
        super.initialize(props)
        this.id = props.id || ''
        this.name = props.name || ''
        this.description = props.description || ''
        this.medOrganizationId = props.medicOrganizationId || ''
        this.active = props.active || false
        this.delete = props.delete || false
    }
}

export default Type
