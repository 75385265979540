import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ReeValidate from 'ree-validate';

import Transformer from '~/utils/Transformer';
import translateErrMessage from "~/utils/translateErrMessage";

import styles from './ModalSmallEdit.module.scss';
import RootModal from '../RootModal/RootModal';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';

const ModalSmallEdit = ({
  title,
  isOpen,
  onClose,
  onSubmit,
  current,  
}) => {
  const validatorModalEdit = new ReeValidate.Validator({		
		email: 'required|email',
    lastName: 'required|min:1',
    name: 'required|min:1',	
    secondName: '',		
	});

  const [formData, setFormData] = useState({
    email: current.email,
    lastName: current.lastName,
    name: current.name,
    secondName: current.secondName,
  });

  const [errorsModalEdit, setErrorsModalEdit] = useState({
    errors: validatorModalEdit.errors,  
  });  

  const handleChange = (event) => {
    const {errors} = validatorModalEdit;  
    const {name, value} = event.target;
    setFormData({...formData, [name]: value});

    errors.remove(name);

    validatorModalEdit.validate(name, value).then(() => {
      setErrorsModalEdit({
        ...errorsModalEdit,
        errors
      });
    });
  };

  const submit = () => {
    const item = formData;
    const {errors} = validatorModalEdit; 

    validatorModalEdit.validateAll(item).then((success) => {
      if (success) {
        const serialized = Transformer.send(formData);
        return onSubmit && onSubmit(serialized);
      } else {
        setErrorsModalEdit({
          ...errorsModalEdit,
          errors
        });
      }
    });     
  };

  return (
    <RootModal onClose={onClose} isOpen={isOpen}>
      <div className={styles.block}>
        {title && <div className={styles.title}>{title}</div>}
        <div className={styles.row}>
          <Input
            name='email'
            value={formData?.email ?? ''}
            label='E-mail'
            syntRequired
            onChange={handleChange}
            bottom={translateErrMessage({errors: errorsModalEdit.errors.first('email')})}
          />
         
        </div>
        <div className={styles.row}>
          <Input
            name='lastName'
            value={formData?.lastName ?? ''}
            label='Фамилия'
            syntRequired
            onChange={handleChange}
            bottom={translateErrMessage({errors: errorsModalEdit.errors.first('lastName')})}
          />          
        </div>
        <div className={styles.row}>
          <Input
            name='name'
            value={formData?.name}
            label='Имя'
            syntRequired
            onChange={handleChange}
            bottom={translateErrMessage({errors: errorsModalEdit.errors.first('name')})}
          />          
        </div>
        <div className={styles.row}>
          <Input
            name='secondName'
            value={formData?.secondName}
            label='Отчество'
            onChange={handleChange}
          />
        </div>
        <div className={styles.buttons}>
          <Button mode='secondary' onClick={onClose}>
            Отменить
          </Button>
          <Button onClick={submit}>Сохранить</Button>
        </div>
      </div>
    </RootModal>
  );
};

ModalSmallEdit.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  current: PropTypes.object.isRequired,
};

export default ModalSmallEdit;
