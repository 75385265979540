// user action types
export const USER_UPDATE = 'USER_UPDATE'
export const USER_LIST = 'USER_LIST'
export const USER_ADD = 'USER_ADD'
export const USER_REMOVE = 'USER_REMOVE'

export default {
  USER_LIST,
  USER_ADD,
  USER_REMOVE,
  USER_UPDATE,
}