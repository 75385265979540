//import libs
import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import components
import Navigation from '../common/navigation/index'
import ScrollTop from '../common/scroll-top/index'
import SideBar from "../common/sidebar";
import {loadDoctorsList} from "../store/modules/doctors/service";
import SIP from '../modules/SIP/SIP';

const containerStyle = {
  paddingTop: 'var(--header-height)',
  height: '100vh'
}

const displayName = 'Private Layout'
const propTypes = {
  children: PropTypes.node.isRequired,
  breadcrumbs: PropTypes.array,
}


function PrivateLayout({ children, dispatch, searchMode, location, ...rest }) {
  
  const [isOpen, setOpen] = useState(true)
  const toggle = () => setOpen(!isOpen)  
  useEffect(() => {
    loadDoctorsList(
      dispatch,         
      '/users/doctors?count_on_page=500&fired=false&active=true&vacation=false',
      true
    )
  }, [dispatch])

  const props = {children, dispatch, searchMode, location, ...rest}

  return <div style={containerStyle}>
    <Navigation searchMode={searchMode} toggleSidebar={toggle}/>
    <div className="App wrapper">
      <SideBar toggle={toggle} isOpen={isOpen} pathName={location.pathname} />
      <div
        style={{ width: '100%'}}
        className='main-container' 
      >
        { children }
        <ScrollTop />
      </div>
    </div>
    <ToastContainer/>
    {(location.pathname.startsWith('/doctor') || location.pathname.startsWith('/patient')) && <SIP { ...props} />}
  </div>
}

PrivateLayout.dispatch = displayName
PrivateLayout.propTypes = propTypes

export default PrivateLayout
