export default function (name: string, surName: string, lastName: string) {
  const items = [];

  if (name) {
    items.push(name);
  }
  if (surName) {
    items.push(surName);
  }
  if (lastName) {
    items.push(lastName);
  }
  return items.join(' ');
}

export function getFullNameByUser(user: any) {
  const items = [];

  if (user?.lastName) {
    items.push(user.lastName);
  }
  if (user?.name) {
    items.push(user.name);
  }
  if (user?.secondName) {
    items.push(user.secondName);
  }
  return items.join(' ');
}
