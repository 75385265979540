import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Button.module.scss';

const Button = ({
  children,
  onClick,
  mode,
  className,
  disabled,
  stretched,
  modified,         
  ...rest
}) => {
  return (
    <button
      className={classNames(
        styles.button,
        styles[mode],
        {
          [styles.disabled]: disabled,
          [styles.stretched]: stretched,
          [styles.modified]: modified,
        },
        className
      )}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  mode: 'primary',
};

Button.propTypes = {
  mode: PropTypes.oneOf([
    'outline',
    'primary',
    'secondary',
    'destructive',
    'outline-destructive',
  ]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  stretched: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  modified: PropTypes.bool,
};

export default Button;
