import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import * as sipActions from './store/actions'; 

import { phoneBookRequest, readyCallback, errorCallback, callSession } from './servise';

import ModalAV from '../../modals/ModalAV/ModalAV';
import Contract from '../contract/Contract';
import User from '../user/User';

const SIP = ({dispatch, user}) => {
  
  // for D'n'D coordinates
  const [coordinates, setCoordinates] = useState({
    right: 10,
    top: 10,
    coordX: null,
    coordY: null,
  });

  const [isDrop, setIsDrop] = useState(false);
  
  //to get the video window sizes
  const [isMobileCall, setIsMobileCall] = useState(false);

  const activeContract = useSelector((state) => new Contract(state.contracts.activeContract));
  const phoneBook = useSelector((state) => state.sip.phoneBook);
  const otherNumber = useSelector((state) => state.sip.otherNumber);
  const otherUser = useSelector((state) => new User(state.sip.otherUser));
  const status = useSelector((state) => state.sip.status);
  const isModalOpen = useSelector((state) => state.sip.isModalOpen);
  const isFullScreen = useSelector(state => state.sip.isFullScreen);

  const modalRef = useRef(null);
  
  //get phoneBook  
  useEffect(() => {
    dispatch(phoneBookRequest())     
  }, [])

  useEffect(() => {
    if (isDrop && !isFullScreen) {
      document.addEventListener('mousemove', onMouseMove);
    }
    
    return () => document.removeEventListener('mousemove', onMouseMove);
  }, [isDrop, isFullScreen])

  //initialize SIP
  useEffect(() => {
    if (user.id) {
      window.SIPml.init(readyCallback({
        myNumber: user.userNumber, 
        callback: (data, e) => {
          dispatch(sipActions.changeStatus(data));
          //message getting
          e && dispatch(sipActions.getMessage(e.getContentString()));
          e && e.getContentString() === 'ANSWER_OK' && dispatch(sipActions.setConnectionStatus(true))                       
        }      
      }), errorCallback);    
    }     
  }, [user.id])
  
  // get other user's number
  useEffect(() => {
    callSession && dispatch(sipActions.setOtherNumber(callSession.getRemoteFriendlyName()))
  }, [callSession])

  useEffect(() => {
    if (!isModalOpen) {
      setTimeout(() => setCoordinates({
          right: 10,
          top: 10,
          coordX: null,
          coordY: null,
        })
      , 1000)
    }
  }, [isModalOpen])

  useEffect(() => {
    setOtherUser({user, activeContract, status, phoneBook, otherNumber})
  }, [status, isModalOpen, otherNumber])

  //automatic caller ID
  function setOtherUser ({user, activeContract, status, phoneBook, otherNumber}) {
      
    if (otherNumber) {      
      dispatch(sipActions.setOtherUser(phoneBook.find(contact => contact.userNumber === otherNumber)))
    } else if (status === 'me_new_call') {
      if (user.isDoctor) {
        dispatch(sipActions.setOtherUser(activeContract.patient))
      } else if (user.isPatient) {
        dispatch(sipActions.setOtherUser(activeContract.doctor))
      } else {
        dispatch(sipActions.setOtherUser({}))
      }    
    }    
  };  

  // Modal Drag'n'Drop
  function onMouseDown ({HandleEvent, elementRef}) {
    
    if (HandleEvent.currentTarget.dataset.drag !== 'noDrag') {
      HandleEvent.persist();
      setIsDrop(true);    
      setCoordinates((coordinates) => {      
        return {
          ...coordinates,
        coordX: elementRef.current.getBoundingClientRect().right - HandleEvent.clientX,
        coordY: HandleEvent.clientY - elementRef.current.getBoundingClientRect().top,    
        } 
      }) 
    } else {
      HandleEvent.stopPropagation()
    }   
  }
  // Modal Drag'n'Drop continuation
  function moveAt(pageX, pageY, isDrop) {    
    if (isDrop) {      
      setCoordinates((coordinates) => {
        return {
          ...coordinates,
        right: document.documentElement.clientWidth - pageX - coordinates.coordX,
        top: pageY - coordinates.coordY,    
        }          
      })  
    }     
  }
  // Modal Drag'n'Drop continuation
  function onMouseMove(HandleEvent) {
    moveAt(HandleEvent.pageX, HandleEvent.pageY, isDrop);
  }
  // Modal Drag'n'Drop continuation
  function onMouseUp () {
    setIsDrop(false);    
  };

  //to get the video window sizes
  function setMobileVideoCallStatus ({ videoRef }) {
    
    if (videoRef.videoHeight > videoRef.videoWidth) {
      setIsMobileCall(true);      
    } else {
      setIsMobileCall(false);      
    }
  }
  
  const props = {
    otherUser,
    modalRef,
    coordinates,
    onMouseDown: (e) => onMouseDown({
      HandleEvent: e,
      elementRef: modalRef,  
    }),   
    onMouseUp,
    isMobileCall,    
    setMobileVideoCallStatus,
  }

  return (
    <ModalAV {...props} />    
  );
};

export default SIP;