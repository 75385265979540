// import libs
// eslint-disable-next-line no-unused-vars
import React, {useState} from 'react';
import PropTypes from 'prop-types';

// import components
import {
  Collapse,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,  
} from 'reactstrap';
import ModalSmallEdit from '../../modals/ModalSmallEdit/ModalSmallEdit';
import ModalChangePass from '../../modals/ModalChangePass/ModalChangePass';
import ModalNotify from '../../modals/ModalNotify/ModalNotify';
import ApiService from '~/utils/ApiService';
import { getFullNameByUser } from '~/utils/getFullName';
import IconButton from '../../components/IconButton/IconButton';
import {ReactComponent as Logout} from '~/icons/IconLogout.svg';
import {ReactComponent as Notify} from '~/icons/IconNotify.svg';
import {ReactComponent as NoNotify} from '~/icons/IconNoNotify.svg';
import {ReactComponent as Person} from '~/icons/IconPersonPlaceholder.svg';
import styles from './styles.module.scss';
import {useDispatch, useSelector} from 'react-redux';
import {editUserData} from '../../modules/users/service';
import { userPatchRequest } from '../../modules/user/service';

// define component name
const displayName = 'PrivateHeader';

// validate properties
const propTypes = {
  user: PropTypes.object.isRequired,
  showNavigation: PropTypes.bool.isRequired,
  showDropdown: PropTypes.bool.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  toggleSidebar: PropTypes.func,
};

// initiate comppnent
const PrivateHeader = ({
  user,
  showNavigation,
  showDropdown,
  toggleDropdown,
  toggleSidebar,
  logout,
}) => {
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [modalPasswordIsOpen, setModalPasswordIsOpen] = useState(false);
  const [modalNotifyIsOpen, setModalNotifyIsOpen] = useState(false);
  
  const {email, lastName, name, secondName, isAdmin, photo, id, isSystemAdmin} = user;

  const isNotify = useSelector(state => state.user.notifyEnabled);
  
  const dispatch = useDispatch();

  const submitChangeUser = (data) => {
    const serializedData = {
      name: data.name ?? '',
      last_name: data.last_name ?? '',
      email: data.email ?? '',
      second_name: data.second_name ?? '',
    };
    dispatch(
      editUserData(user.id, serializedData, () => setModalEditIsOpen(false))
    );
  };

  const submitChangeNotify = () => {
    const notifyData = {
      notifyEnabled: !isNotify
    };     
    dispatch(userPatchRequest(id, notifyData, null, false));
  }

  const submitChangePassword = async ({oldPass, newPass}) => {
    new ApiService().changePassword({
      oldPass,
      newPass,
    })
      .then(() =>  setModalPasswordIsOpen(false))
  };

  function selectImg(imgSrc) {
    if (imgSrc) {
      return (
        <img
          className={styles.photo}
          src={imgSrc}
          alt='img'
        />
      );
    } else {
      return (
        <div className={styles.avatar}>
          <Person />
        </div>
      );
    }  
  }

  return (
    <>
      <Collapse
        className="navbar-collapse justify-content-end"
        isOpen={showNavigation}>
        <ul className="navbar-nav mt-0">
          <Dropdown isOpen={showDropdown} toggle={toggleDropdown}>
            <DropdownToggle nav className="p-0 mr-5">
              <div className={styles.profile}>
                {isAdmin || isSystemAdmin ?
                  <div className={styles.avatar}>
                    <Person />
                  </div> :
                   selectImg(photo)
                }
                {getFullNameByUser(user)}
              </div>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-right px-2">
              <DropdownItem onClick={() => setModalEditIsOpen(true)}>
                <span
                  className="fa fa-user-o"
                  title="logout"
                  aria-hidden="true"
                />
                Редактирование профиля
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => setModalPasswordIsOpen(true)}>
                <span className="fa fa-sign-out" aria-hidden="true" />
                Изменить пароль
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          {!isAdmin && !isSystemAdmin && isNotify !== undefined &&
          <div className={styles.btnBlock}>
            <IconButton size="lg" onClick={() => setModalNotifyIsOpen(!modalNotifyIsOpen)}>
              {isNotify ?
                <Notify />
                :
                <NoNotify />                
              }
            </IconButton>
          </div>         
          }
          <div className={styles.btnBlock}>
            <IconButton size="lg" onClick={(e) => logout(e)}>
              <Logout />
            </IconButton>
          </div>
        </ul>
      </Collapse>
      {modalNotifyIsOpen && (
        <ModalNotify
          isOpen={modalNotifyIsOpen}
          onClose={() => setModalNotifyIsOpen(false)}
          onChange={submitChangeNotify}
          isNotify={isNotify}          
        />
      )}
      {modalEditIsOpen && (
        <ModalSmallEdit
          current={{email, name, lastName, secondName}}
          onClose={() => setModalEditIsOpen(false)}
          isOpen={modalEditIsOpen}
          onSubmit={submitChangeUser}
        />
      )}
      {modalPasswordIsOpen && (
        <ModalChangePass
          title="Изменить пароль"
          onClose={() => setModalPasswordIsOpen(false)}
          isOpen={modalPasswordIsOpen}
          onSubmit={submitChangePassword}
        />
      )}
    </>
  );
};

// bind properties
PrivateHeader.displayName = displayName;
PrivateHeader.propTypes = propTypes;

// export component
export default PrivateHeader;
