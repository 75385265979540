 import { combineReducers } from 'redux'

import auth from '../modules/auth/store/reducer'
import user from '../modules/user/store/reducer'
import articles from '../modules/article/store/reducer'
import contracts from '../modules/contract/store/reducer'
import types from '../modules/type/store/reducer'
import rules from '../modules/rule/store/reducer'
import medorg from '../modules/medorg/store/reducer'
import users from '../modules/users/store/reducer'
import doctors from './modules/doctors/doctors-reducer'
import reports from '../modules/admin/pages/reports/store/reducer'
import search from './modules/search/search-reducer'
import chats from '../modules/ChatRoom/store/reducer'
import sip from '../modules/SIP/store/reducer'
import organization from '../modules/landing/store/reducer'

export default combineReducers({
    auth,
    user,
    articles,
    contracts,
    types,
    users,
    medorg,
    rules,
    doctors,
    reports,
    search,
    chats,
    sip,
    organization,
})
