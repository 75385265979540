export const MED_LIST = 'MED_LIST'
export const MED_ADD = 'MED_ADD'
export const MED_UPDATE = 'MED_UPDATE'
export const MED_REMOVE = 'MED_REMOVE'
export const MED_GET = 'MED_GET'

export default {
  MED_LIST,
  MED_ADD,
  MED_UPDATE,
  MED_REMOVE,
  MED_GET
}