import React from "react";

import styles from './FileNameItem.module.scss'

const FileNameItem = ({fileName, fileSize, deleteFile, id}) => {

	return (
		<li className={styles.nameItem}>			
			<div>
				<span className={styles.fileName}>
					{fileName}
				</span>
				<span className={styles.fileSize}>
					{fileSize}
				</span>
			</div>
			<div>
				<span className={styles.closeBtn}
					id={id}
					onClick={deleteFile}
				></span>	
			</div>			
		</li>
	);
};

export default FileNameItem;