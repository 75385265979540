import echo from './echo';
import { PUSHER_APP_KEY } from './keys';


export const chat = echo({
  key: PUSHER_APP_KEY,
  authEndpoint: '/api/broadcasting/auth',
  //wsPath: '/service'
  auth: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    },
});

export const chatMessages = (chatId: any) => chat.private(`chat.${chatId}`);
export const status = () => chat.channel(`users-status`);


