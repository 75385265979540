import doctorFemale from '~/images/avatars/doctorFemale.png';
import doctorMale from '~/images/avatars/doctorMale.png';
import patientFemale from '~/images/avatars/patientFemale.png';
import patientMale from '~/images/avatars/patientMale.png';

export default function setAvatar ({isDoctor, isPatient, gender, photo}) {
  if (photo) {
    return photo;
  }	else if (isDoctor && !isPatient && gender === false) {
    return doctorFemale;
  } else if (isDoctor && !isPatient && (gender === true || gender === null)) {
    return doctorMale;
  } else if (!isDoctor && isPatient && gender === false) {
    return patientFemale;
  } else if (!isDoctor && isPatient && (gender === true || gender === null)) {
    return patientMale;
  } else {
    return patientMale;
  }
}