import React from 'react';
import PropTypes from 'prop-types';
import styles from './Input.module.scss';
import classNames from 'classnames';

const Input = ({
  name,
  value,
  label,
  bottom,
  placeholder,
  type,
  status,
  disabled,
  required,
  syntRequired,
  isSynthetic,
  className,
  onChange, 
  isAutoComplete 
}) => {
  const change = (e) => {
    if (onChange) {
      if (!isSynthetic) {
        return onChange(e.target.value);
      }
      return onChange(e);
    }
  };

  const params = {name, disabled, required, type, value, placeholder};
  if (type === 'number') {
    params.min = 0;
  } 
  
  return (
    <div className={classNames(styles.block, styles[status], className)}>
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label && <div className={styles.top}>{label}</div>}
          {(required || syntRequired) && <div className={styles.required}>*</div>}
        </label>
      )}
      <input
        className={styles.input}
        onChange={change}     
        autoComplete={type==='password' && !isAutoComplete ? "new-password" : ''}       
        {...params}
      />
      <div className={styles.bottom}>{bottom}</div>
    </div>
  );
};

Input.defaultProps = {
  type: 'text',
  required: false,
  isSynthetic: true,
  syntRequired: false,
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  bottom: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  syntRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  isSynthetic: PropTypes.bool,
  status: PropTypes.oneOf(['success', 'error', 'default']),
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  isAutocomplete: PropTypes.bool,
  syntRequired: PropTypes.bool,
};

export default Input;
