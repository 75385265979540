function setRedirectPath(user, endPath ='/') {
  if (user.isAdmin ) {
    return '/admin';
  } else if (user.isDoctor) {
    return '/doctor';
  } else if (user.isPatient) {
    return '/patient';  
  } else if (user.isSystemAdmin) {
    return '/system-admin';
  } else return endPath;
}

export default setRedirectPath;