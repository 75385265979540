import Http from './Http';
import {AxiosRequestConfig} from 'axios';

export type ApiServiceSearchModes = 
  'doctors' |
  'firedDoctors' |
  'patients' |
  'contracts' |
  'archiveContracts' |
  'medOrgs' |
  'admins' |
  'contractTypes' |
  'archiveContractTypes' |
  'contractRules' |
  'archiveContractRules';

export interface CreateAdministratorData {
  med_organization_id: number;
  name: string;
  last_name: string;
  email: string;
  password: string;
  password_confirmation: string;
  active: boolean;
  notify_enabled: boolean;
}

export interface ResetPasswordData {
  password: string;
  password_confirmation: string;
  token: string;
}

interface IChangePassOther {
  id: number
  newPass: string,
}

interface SearchParams {  
  q?: string
  active?: boolean
  archive?: boolean
  fired?: boolean 
  is_admin?: boolean
}

export default class ApiService {
  async get(url: string, config?: AxiosRequestConfig) {
    // @ts-ignore
    return await Http.get(url, config);
  }

  async post(url: string, data: any, config?: AxiosRequestConfig) {
    // @ts-ignore
    return await Http.post(url, data, config);
  }

  async put(url: string, data: any, config?: AxiosRequestConfig) {
    // @ts-ignore
    return await Http.put(url, data, config);
  }

  async changePasswordById({id, newPass}: IChangePassOther) {
    const {data} = await this.post(`/password/change/${id}`, {
      password: newPass,
      password_confirmation: newPass,
    });

    return data;
  }
  async changePassword({oldPass, newPass}: any) {
    const {data} = await this.post('/password/change', {
      password: newPass,
      password_confirmation: newPass,
      old_password: oldPass,
    });

    return data;
  }

  async forgotPassword(field: string) {
    const {data} = await this.post('/password/forgot', {
      email: field,
    });

    return data;
  }

  async resetPassword(props: ResetPasswordData) {
    const {data} = await this.post('/password/reset', props);

    return data;
  }

  async search(term: string, params: {mode: ApiServiceSearchModes}) {
    let response = [];

    switch (params.mode) {
      case 'doctors':
        response = await this.getDoctorsByQuery(term);
        break;
      case 'firedDoctors':
        response = await this.getFiredDoctorsByQuery(term);
        break;
      case 'patients':
        response = await this.getPatientsByQuery(term);
        break;
      case 'contracts':
        response = await this.getContractsByQuery(term);
        break;
      case 'archiveContracts':
        response = await this.getArchiveContractsByQuery(term);
        break;  
      case 'medOrgs':
        response = await this.getMedOrgsByQuery(term);
        break;
      case 'admins':
        response = await this.getAdminsByQuery(term);
        break;  
      case 'contractTypes':
        response = await this.getContractTypesByQuery(term);
        break;  
      case 'archiveContractTypes':
        response = await this.getArchiveContractTypesByQuery(term);
        break;  
      case 'contractRules':
        response = await this.getContractRulesByQuery(term);
        break;
      case 'archiveContractRules':
        response = await this.getArchiveContractRulesByQuery(term);
        break;      
    }

    return response;
  }

  async getContractsByQuery(query: string) {
    const params: SearchParams = {
      active: true,
      archive: false, 
    }
    if (query) {
      params.q = query
    }     
    const {data} = await this.get('/contracts', {
      params: params,
    });
    return data.data;
  }

  async getArchiveContractsByQuery(query: string) {
    const params: SearchParams = {
      active: false,
      archive: true, 
    }
    if (query) {
      params.q = query
    }    
    const {data} = await this.get('/contracts', {
      params: params,
    });
    return data.data;
  }

  async getMedOrgsByQuery(query: string) {
    const params: SearchParams = {}
    if (query) {
      params.q = query
    }    
    const {data} = await this.get('/med_organizations', {
      params: params,
    });
    return data.data;
  }

  async getFiredDoctorsByQuery(query: string) {
    const params: SearchParams = {
      fired: true,       
    }
    if (query) {
      params.q = query
    }    
    const {data} = await this.get('/users/doctors', {
      params: params,
    });
    return data.data;
  }

  async getDoctorsByQuery(query: string) {
    const params: SearchParams = {
      fired: false,       
    }
    if (query) {
      params.q = query
    }    
    const {data} = await this.get('/users/doctors', {
      params: params,
    });
    return data.data;
  }

  async getPatientsByQuery(query: string) {
    const params: SearchParams = {}
    if (query) {
      params.q = query
    }  
    const {data} = await this.get('/users/patients', {
      params: params,
    });
    return data.data;
  }

  async getAdminsByQuery(query: string) {
    const params: SearchParams = {
      is_admin: true,  
    }
    if (query) {
      params.q = query
    } 
    const {data} = await this.get('/users', {
      params: params,
    });
    return data.data;
  }

  async getContractTypesByQuery(query: string) {
    const params: SearchParams = {
      active: true,
      archive: false, 
    }
    if (query) {
      params.q = query
    }    
    const {data} = await this.get('/contract_types', {
      params: params,
    });
    return data.data;
  }

  async getArchiveContractTypesByQuery(query: string) {
    const params: SearchParams = {
      active: false,
      archive: true, 
    }
    if (query) {
      params.q = query
    }    
    const {data} = await this.get('/contract_types', {
      params: params,
    });
    return data.data;
  }

  async getContractRulesByQuery(query: string) {
    const params: SearchParams = {
      active: true,
      archive: false, 
    }
    if (query) {
      params.q = query
    }    
    const {data} = await this.get('/contract_rules', {
      params: params,
    });
    return data.data;
  }

  async getArchiveContractRulesByQuery(query: string) {
    const params: SearchParams = {
      active: false,
      archive: true, 
    }
    if (query) {
      params.q = query
    }    
    const {data} = await this.get('/contract_rules', {
      params: params,
    });
    return data.data;
  }

  async createAdministrator(props: CreateAdministratorData) {
    const {data} = await this.post(`/users/admins`, props);

    return data.data;
  }

  async editAdministrator(props: CreateAdministratorData & {id: number}) {
    const {id} = props;
    const {data} = await this.put(`/users/admins/${id}`, props);

    return data.data;
  }

  async getContractHistoryByIdRequest(id: string) {
    const {data} = await this.get(`/contract_history/contract/${id}`, {});
    return data.data;
  }
  
  async getPhoneBook() {
    const { data } = await this.get('/users/phonebook', {});
    return data.data;
  }
}
