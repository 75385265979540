import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ReeValidate from 'ree-validate';
import { toast } from 'react-toastify';

import translateErrMessage from "~/utils/translateErrMessage";
import passwordValidation from '~/utils/passwordValidation';

import styles from './ModalChangePass.module.scss';
import RootModal from '../RootModal/RootModal';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';

const ModalChangePass = ({
  title,
  isOpen,
  needOldPassword,
  onClose,
  onSubmit,
}) => {
  
  const validatorPasswordChange = new ReeValidate.Validator({
    oldPass: needOldPassword ? 'required|min:8' : '',
    newPass: 'required|min:8',
    reNewPass: 'required|min:8',
  });
  
  const [state, setState] = useState({
    oldPass: '',
    newPass: '',
    reNewPass: '',    
  });

  const [errorState, setErrorState] = useState({
    errors: validatorPasswordChange.errors,  
  });

  const handleChange = (e) => { 
   
    const {errors} = validatorPasswordChange;     
    const {name, value} = e.target;
    setState({...state, [name]: value});
    
    errors.remove(name);

    validatorPasswordChange.validate(name, value).then(() => {
      setErrorState({
        ...errorState,
        errors
      });
    });
  };  

  const submit = () => { 
    const item = state;
    const {errors} = validatorPasswordChange;    
    
    validatorPasswordChange.validateAll(item).then((success) => {
      
      if (success && state.newPass === state.reNewPass) {
        onSubmit({ oldPass: state.oldPass, newPass: state.newPass })
      } else { 
        if (!passwordValidation(state.newPass)) {
          toast('Пароль должен содержать не менее 8 знаков, включать строчные, заглавные латинские буквы, цифры и специальные символы')
        }  
        setErrorState({
          ...errorState,
          errors
        });
      }
    });    
  }

  return (
    <RootModal onClose={onClose} isOpen={isOpen}>
      <div className={styles.block}>
        {title && <div className={styles.title}>{title}</div>}
        {needOldPassword && (
          <div className={styles.row}>
            <Input
              name="oldPass"
              type="password"
              value={state.oldPass}
              label="Старый пароль"
              isAutoComplete={false}
              syntRequired
              onChange={handleChange}
              bottom={translateErrMessage({
                errors: errorState.errors.first('oldPass'),
              })}
            />
          </div>
        )}
        <div className={styles.row}>
          <Input
            name="newPass"
            type="password"
            value={state.newPass}
            label="Новый пароль"
            syntRequired
            onChange={handleChange}
            bottom={translateErrMessage({
              errors: errorState.errors.first('newPass'),              
            })}
          />
        </div>
        <div className={styles.row}>
          <Input
            name="reNewPass"
            type="password"
            value={state.reNewPass}
            label="Подтверждение пароля"
            syntRequired
            onChange={handleChange}
            bottom={translateErrMessage({
              errors: errorState.errors.first('reNewPass'),
              newPass: state.newPass,
              reNewPass: state.reNewPass,
            })}
          />
        </div>
        <div className={styles.buttons}>
          <Button mode="secondary" onClick={onClose}>
            Отменить
          </Button>
          <Button onClick={submit}>Сохранить</Button>
        </div>
      </div>
    </RootModal>
  );
};

ModalChangePass.defaultProps = {
  needOldPassword: true,
};

ModalChangePass.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  needOldPassword: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};

export default ModalChangePass;
