// import lib
import { lazy } from 'react'

const routes = [ 
 /*  {
    path: '/blog',
    exact: true,
    component: lazy(() => import('./pages/blog/list/index')),
  },
  {
    path: '/blog/:id',
    exact: true,
    component: lazy(() => import('./pages/blog/details/index')),
  },
  {
    path: '/usercontracts',
    exact: true,
    component: lazy(() => import('./pages/contracts/list/index')),
  },
  {
    path: '/usercontracts/:id',
    exact: true,
    component: lazy(() => import('./pages/contracts/details/index')),
  }, */
]

export default routes
