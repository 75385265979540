// import lib
import { lazy } from 'react'

export default [
 /*  {
    path: '/profile/edit',
    exact: true,
    auth: true,
    component: lazy(() => import('./pages/edit/index')),
  }, */
]
