function translateErrMessage({errors, newPass=null, reNewPass=null, isNewPass=false, isPhone=false}) {
  if (errors && errors.includes('field is required.'))  {
    return 'Поле обязательно к заполнению!'
  } else if (errors === 'The email field must be a valid email.') {
    return 'Введите корректный адрес почты!'
  } else if (newPass !== reNewPass && newPass && reNewPass) {
    return 'Пароли должны совпадать!'
  } else if (errors && errors.includes('must be at least') && isNewPass) {
    return `Пароль должен содержать не менее ${errors.substr(36, 1)} знаков, включать строчные и заглавные латинские буквы`  
  } else if (errors && errors.includes('must be at least') && isPhone) {
    return 'Введите корректный номер телефона!'
  }
}

export default translateErrMessage;