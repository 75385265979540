import {
  CHAT_LIST,
  UPDATE_LIST,
  MESSAGE_ADD,
  UPDATE_STATUS,
  SEARCH_LIST,
  GET_ID,
  SET_SEARCH, 
  ADD_PAGE_NUMBER,
  SET_NEW_OTHER_USER_MESSAGE, 
} from './action-types'

const initialState = {
  currentPage: null,
  data: [],
  lastSeen: '',
  searchData: null,
  forScrollMessageId: null,
  isSearch: false,
  loadPages: [], 
  isNewOtherUserMessage: false,  
};

const reducer = (state = initialState, { type, payload = null }) => {
  switch(type) {
    case MESSAGE_ADD:
      return add(state, payload)    
    case CHAT_LIST:
      return list(state, payload)
    case UPDATE_LIST:
      return update(state, payload)
    case UPDATE_STATUS:
      return updateStatus(state, payload)
    case SEARCH_LIST:
      return searchList(state, payload) 
    case GET_ID:
      return getId(state, payload)
    case SET_SEARCH:
      return setSearch(state, payload)
    case ADD_PAGE_NUMBER:
      return addPageNumber(state, payload)
    case SET_NEW_OTHER_USER_MESSAGE:
      return setNewOtherUserMessage(state, payload)                 
    default:
      return state
  }
};

function add(state, payload) {
  const newData = state.data.filter((element) => element.id !== payload.id)
  
  return {
    ...state,
    data: [...newData, payload]
  }
}

function list(state, payload) {
  payload.loadPages = [payload.currentPage];
  return {  
    ...state, ...payload,       
  }
}

function update(state, payload) {
  let data = [];

  [ ...payload.data, ...state.data].sort((prev, next) => {
    return (prev.id - next.id)
  }).forEach((item, i) => {    
    let check = 0;

    if (i > 0) {      
      data.forEach(item2 => {
        
        if (item.id == item2.id) {
          check = 1;
        }
      })
    }  

    if (check === 0) {
      data.push(item); 
    }
  });    

  return {
    ...state,
    data,
    currentPage: payload.currentPage
  }
}

function updateStatus(state, payload) {
  return {
    ...state,
    lastSeen: payload
  }
}

function searchList(state, payload) {
  return {
    ...state,
    searchData: payload
  }
}

function getId(state, payload) {
  return {
    ...state,
    forScrollMessageId: payload
  }
}

function setSearch(state, payload) {
  return {
    ...state,
    isSearch: payload
  }
}

function addPageNumber(state, payload) {
  return {
    ...state,
    loadPages: [...state.loadPages, payload]
  }
}

function setNewOtherUserMessage(state, payload) {
  return {
    ...state,
    isNewOtherUserMessage: payload
  }
}

export default reducer
