import React from 'react';

import styles from './FileNameList.module.scss'

const FileNameList = ({nameElements}) => {
	return (
		<>
			<ul className={styles.nameList}>
				{nameElements}
			</ul>
		</>
	);
};

export default FileNameList;