import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './IconButton.module.scss';

const IconButton = ({children, onClick, mode, size}) => {
  return (
    <div
      className={classNames(styles.button, styles[mode], styles[size])}
      role="button"
      onClick={onClick}>
      {children}
    </div>
  );
};

IconButton.defaultProps = {
  mode: 'primary',
  size: 'md',
};

IconButton.propTypes = {
  mode: PropTypes.oneOf(['primary', 'secondary', 'warning', 'danger']),
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  disabled: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default IconButton;
