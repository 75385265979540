export const ARTICLE_LIST = 'ARTICLE_LIST'
export const ARTICLE_ADD = 'ARTICLE_ADD'
export const ARTICLE_UPDATE = 'ARTICLE_UPDATE'
export const ARTICLE_REMOVE = 'ARTICLE_REMOVE'

export default {
  ARTICLE_LIST,
  ARTICLE_ADD,
  ARTICLE_UPDATE,
  ARTICLE_REMOVE,
}