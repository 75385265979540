import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import translateErrMessage from "~/utils/translateErrMessage";


import styles from './ModalFeedBack.module.scss';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import TextArea from '../../components/TextArea/TextArea';
import FileNameList from './FileNameList/FileNameList';

import {ReactComponent as IconClip} from '~/icons/IconClip.svg';
import { BASE_MANUAL_URL } from '../../common/url';

const ModalFeedBack = ({ 
  onClose,  
  isAuthorized,  
  uploadFile,
  isOpen,
  state,
  handleChange,
  errorsModalFeedBack,
  nameElements,
  handleSubmit,
  instructionLink,
}) => {

  const customStyles = {
		content: {
			width: 'auto',
			height: 'auto',						
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			padding: '0',
			transform: 'translate(-50%, -50%)',
			borderRadius: '8px'
		},
		overlay: {
      position: 'fixed',
			top: '0',
			left: '0',
			right: '0',					
			background: 'rgba(0, 0, 0, 0.25)',
			transition: '1s',
      zIndex: 1031,
    },
	};  
    
  return (
    <Modal 
      onRequestClose={onClose} 
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}	
      style={customStyles}
    > 
      <div className={styles.modal__container}>
        <div className={styles.modal__header}>
          <h3 className={styles.modal__title}>Поддержка</h3>
          <span className={styles.modal__close} onClick={onClose}></span>
        </div>       
        
        {isAuthorized && <p className={styles.modal__desc}>
          Имеется вопрос по функционалу? Откройте файл с <a href={`${BASE_MANUAL_URL}${instructionLink}.pdf`} target='_blank' >инструкцией</a>.
          Если же вопрос остался или имеется проблема - заполните форму ниже    
        </p>}
        {!isAuthorized && <div className={styles.modal__row}>
          <Input
            name='email'
            type='email'        
            value={state.email}
            label='Email'
            onChange={handleChange}
            bottom={translateErrMessage({errors: errorsModalFeedBack.errors.first('email')})}
          />
        </div>}	
        {!isAuthorized && <div className={styles.modal__row}>
          <Input
            name='name'
            value={state.name}
            label='Ваше имя'
            onChange={handleChange}
            bottom={translateErrMessage({errors: errorsModalFeedBack.errors.first('name')})}
          />
        </div>}
        <div className={styles.modal__row}>
          <Input
            name='title'               
            value={state.title}
            label='Тема обращения'
            onChange={handleChange}
            bottom={translateErrMessage({errors: errorsModalFeedBack.errors.first('title')})}        
          />
        </div>
        <div className={styles.modal__row}>
          <TextArea
            rows='4'
            name='message'
            value={state.message}
            label='Обращение' 
            onChange={handleChange}
            bottom={translateErrMessage({errors: errorsModalFeedBack.errors.first('message')})}               
          />
        </div>
        <div className={styles.modal__fileBtn}>
          <label htmlFor='feed-back-file-input'>
            <IconClip/>            
            <input
              id='feed-back-file-input'              
              type='file'
              multiple
              value=''
              onChange={uploadFile}
            />				
          </label>
        <FileNameList
          nameElements={nameElements}
        />
        </div>        
        <div className={styles.modal__submitBtn}>          
          <Button onClick={handleSubmit}>Отправить</Button>
        </div>
      </div>      
  </Modal>
  );
};

ModalFeedBack.propTypes = {
  children: PropTypes.node,  
  current: PropTypes.object,
  onClose: PropTypes.func.isRequired,  
  isAuthorized: PropTypes.bool,  
  uploadFile: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  state: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  errorsModalFeedBack: PropTypes.object.isRequired,
  nameElements: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  instructionLink: PropTypes.string
};

export default ModalFeedBack;