import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import {ReactComponent as IconInfo} from '~/icons/sideBar/IconInfo.svg';
import { NavItem, NavLink} from 'reactstrap';
import {Link} from 'react-router-dom';

import { getFullNameByUser } from '~/utils/getFullName'

import SideMenu from './SideMenu';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faFileWord, faUserNurse, faUser, faCog, faBook
} from '@fortawesome/free-solid-svg-icons';
import {ReactComponent as IconDialogs} from '~/icons/sideBar/IconDialogs.svg';
import {ReactComponent as IconMonitoring} from '~/icons/sideBar/IconMonitoring.svg';
import {ReactComponent as IconSetings} from '~/icons/sideBar/IconSetings.svg';
import {ReactComponent as IconOrganizations} from '~/icons/sideBar/IconOrganizations.svg';
import Support from '../../modules/Support/Support';

const SideBar = props => {  
  const [userNav, setUserNav] = useState([
    {       
      active: false,
      text: 'Мониторинг',
      path: '/monitoring',
      svg: <IconMonitoring className="svg mr-3 ml-1"/>
    },
    {
      active: true,
      text: 'Диалоги',
      path: '',
      svg: <IconDialogs className="svg mr-3 ml-1"/>
    },
    {       
      active: false,
      text: 'Настройки',
      path: '/profile',
      svg: <IconSetings className="svg mr-3 ml-1"/>
    },
  ]);

  const [adminNav, setAdminNav] = useState([
    {       
      active: true,
      text: 'Главная',
      path: '',
      svg: <FontAwesomeIcon icon={faHome} className="svg mr-3 ml-1"/>
    },
    {
      active: false,
      text: 'Договоры',
      path: '/contracts',
      svg: <FontAwesomeIcon icon={faFileWord} className="svg mr-3 ml-1"/>
    },
    {       
      active: false,
      text: 'Врачи',
      path: '/users/doctors',
      svg: <FontAwesomeIcon icon={faUserNurse} className="svg mr-3 ml-1"/>
    },
    {       
      active: false,
      text: 'Пациенты',
      path: '/users/patients',
      svg: <FontAwesomeIcon icon={faUser} className="svg mr-3 ml-1"/>
    },
    {       
      active: false,
      text: 'Отчеты',
      path: '/reports',
      svg: <FontAwesomeIcon icon={faBook} className="svg mr-3 ml-1"/>
    },
    {       
      active: false,
      text: 'Настройки',
      path: '/settings',
      svg: <FontAwesomeIcon icon={faCog} className="svg mr-3 ml-1"/>
    },
  ]);

  function madeNewNav(path, oldNav) {
    const newNav = oldNav;
    if (props.pathName === path) {
      newNav.forEach((item) => {
        if (item.path) {
          item.active = false; 
        } else {
          item.active = true;
        }       
      })  
    } else {
      newNav.forEach((item) => {          
        if (item.path && props.pathName.includes(item.path)) {
          item.active = true;  
        } else { 
          item.active = false;
        }         
      })
    }
    return newNav;        
  }

  const [sysadminNav, setSysadminNav] = useState([
    {       
      active: true,
      text: 'Медицинские организации',
      path: '',
      svg: <IconOrganizations className="svg mr-3 ml-1"/>
    },    
  ]);

  const [isOpen, setIsOpen] = useState(false);

  const [supportProps, setSupportProps] = useState({
    isAuthorized: true,     
    instructionLink: '',
  });

  useEffect(() => {
    if (props.pathName.includes('system-admin')) {
      setSysadminNav([
        ...madeNewNav('/system-admin', sysadminNav) 
      ])
      setSupportProps({
        ...supportProps,
        instructionLink: '/system-admin',
      }) 
    } else if (props.pathName.includes('doctor')) {      
      setUserNav([
        ...madeNewNav('/doctor', userNav) 
      ])
      setSupportProps({
        ...supportProps,
        instructionLink: '/doctor',
      })       
    } else if (props.pathName.includes('patient')) {
      setUserNav([
        ...madeNewNav('/patient', userNav) 
      ])
      setSupportProps({
        ...supportProps,
        instructionLink: '/patient',
      })       
    } else if (props.pathName.includes('admin')) {       
      setAdminNav([
        ...madeNewNav('/admin', adminNav)  
      ])
      setSupportProps({
        ...supportProps,
        instructionLink: '/admin',
      })      
    }
  }, [props.pathName])   

  const handleClick = (e, state, setState) => {
    const target = e.currentTarget;    
    setState([...state.map((item, i) => {
      if (i == target.id) {
        item.active = true;
        return item; 
      } else {
        item.active = false;
        return item; 
      }
    })])
  };

  function NavElem({ active, path, svg, text, globalPath, onClick, i }) {
    return (
      <NavItem        
        id={i}
        active={active}
        onClick={onClick}
      >
        <NavLink 
          tag={Link}
          to={`${globalPath}${path}`}                       
        >
          {svg}
          {text}
        </NavLink>
      </NavItem>
    );
  };  

  const sideMenu = useSelector(state => {    
    if (state.user.id) { 
      if (state.user.isAdmin) {
        return <SideMenu>
          {adminNav.map((adminItem, i) => {
            const props = { ...adminItem, globalPath: '/admin', i, onClick: (e) => handleClick(e, adminNav, setAdminNav) };
            return <NavElem {...props} key={i} />
          })}
          <div className='infoBtn' onClick={() => setIsOpen(true)}>
            <IconInfo />
            <span>Информация</span>
          </div>  
        </SideMenu>
      } else if (state.user.isDoctor) {
        return <SideMenu>
          {userNav.map((userItem, i) => {
            const props = {...userItem, globalPath: '/doctor', i, onClick: (e) => handleClick(e, userNav, setUserNav)};
            return <NavElem {...props} key={i} />
          })}
          <div className='infoBtn' onClick={() => setIsOpen(true)}>
            <IconInfo />
            <span>Информация</span>
          </div>
        </SideMenu>;
      } else if (state.user.isPatient) {
        return <SideMenu>
           {userNav.map((userItem, i) => {
            const props = {...userItem, globalPath: '/patient', i, onClick: (e) => handleClick(e, userNav, setUserNav)};
            return <NavElem {...props} key={i}/>
          })}
          <div className='infoBtn' onClick={() => setIsOpen(true)}>
            <IconInfo />
            <span>Информация</span>
          </div>
        </SideMenu>; 
      } else if (state.user.isSystemAdmin) {
        return <SideMenu>
           {sysadminNav.map((sysadminItem, i) => {
            const props = {...sysadminItem, globalPath: '/system-admin', i, onClick: (e) => handleClick(e, sysadminNav, setSysadminNav)};
            return <NavElem {...props} key={i}/>
          })}
          <div className='infoBtn' onClick={() => setIsOpen(true)}>
            <IconInfo />
            <span>Информация</span>
          </div>
        </SideMenu>; 
      }
    }
  })  

  return (
    <div className={classNames('sidebar', {'is-open': props.isOpen})}>
      <div className="sidebar-header">
        <span color="info" onClick={props.toggle} style={{color: '#fff'}}>&times;</span>
      </div>
      {sideMenu}
      <Support {...supportProps} onClose={() => setIsOpen(false)} isOpen={isOpen} />   
    </div>
  );
};

export default SideBar;