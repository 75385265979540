import {SEARCH_CLEAR, SEARCH_MODE, SEARCH_PLACEHOLDER, SEARCH_TYPED_FN, SEARCH_VALUE} from './action-types'

const initialState = {
    mode: undefined,
    value: '',
    placeholder: '',
    searchFn: undefined
}
const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case SEARCH_MODE:
            return {
                ...state,
                mode: payload
            }
        case SEARCH_TYPED_FN:
            return {
                ...state,
                searchFn: payload
            }
        case SEARCH_VALUE:
            return {
                ...state,
                value: payload
            }
        case SEARCH_CLEAR:
            return {
                ...state,
                value: ''
            }
        case SEARCH_PLACEHOLDER:
            return {
                ...state,
                placeholder: payload
            }
        default:
            return state
    }
}

export default reducer
